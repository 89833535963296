/**
 * @author: sergeyu
 * Сервис для компонентов Участников
 */
angular.module('uetp').service('customerSrv', ['$state', 'httpSrv',
    function ($state, httpSrv) {
        let customerInfo = {},
            uetpCodes = [],
            customerType = '';
        /**
         * Получить заказчика
         */
        this.getCustomerById = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/customer/${id}`
            });
        }
        this.createUpdateCustomer = function(data) {
            return httpSrv.http({
                url: `/admin/customer/createUpdateCustomer`,
                data: data
            })
        }
        this.updateCustomerEisNds = function(data) {
            return httpSrv.http({
                url: `/admin/customer/updateCustomerEisNds`,
                data: data
            })
        }
        this.createCustomerAndOrganizer = function(data) {
            return httpSrv.http({
                url: `/admin/customer/createCustomerAndOrganizer`,
                data: data
            })
        }

        this.setCustomerInfo = function (customer) {
            customerInfo = customer;
        };

        this.getCustomerInfo = function () {
            return customerInfo;
        };

        this.setUetpCodes = function (codes) {
            uetpCodes = codes;
        };

        this.getUetpCodes = function () {
            return uetpCodes;
        };

        this.setCustomerType = function(type) {
            customerType = type;
        }

        this.hasEisIntegration = function() {
            return !!(customerType && ['head', 'dzo'].includes(customerType));
        }

        /**
         * Способы закупки ЕИС
         * @param id
         * @returns {*}
         */
        this.loadMethodList = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/customer/getPurchaseMethodList/${id}`
            });
        }

        this.getMethodById = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/customer/getPurchaseMethod/${id}`
            });
        }

        this.deleteMethod = function(id) {
            return httpSrv.http({
                method: 'DELETE',
                url: `/admin/customer/deletePurchaseMethod/${id}`
            });
        }

        this.createOrUpdateMethod = function(data) {
            return httpSrv.http({
                method: 'POST',
                data: data,
                url: `/admin/customer/createOrUpdateEisPurchaseMethod`
            });
        }

        /**
         * Филиалы заказчика
         * @param id
         * @returns {*}
         */
        this.getCustomerBranches = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/customer/getCustomerBranches/${id}`
            });
        }

        this.getCustomerBranch = function(id) {
            return httpSrv.http({
                method: 'GET',
                url: `/admin/customer/getCustomerBranch/${id}`
            });
        }

        this.deleteCustomerBranch = function(id) {
            return httpSrv.http({
                method: 'DELETE',
                url: `/admin/customer/deleteCustomerBranch/${id}`
            });
        }

        this.createOrUpdateCustomerBranch = function(data) {
            return httpSrv.http({
                method: 'POST',
                data: data,
                url: `/admin/customer/createOrUpdateCustomerBranch`
            });
        }

    }]);