angular.module('uetp')
    .component('participate', {
        template: '<div ng-include="ctrl.templateUrl">',
        bindings: {
            createData: '<',
            updateData: '<',
            procedure: '<'
        },
        controller: ['$document', '$scope', '$rootScope', '$state', '$timeout', '$stateParams', 'desktopSrv', '$uibModal', 'participateSrv', 'dictionarySrv', 'cryptography', 'authSrv', 'alertSrv', 'profileSrv', 'needConfirmSrv','vfSrv','httpSrv','$filter', 'dialogSrv', 'proceduresSrv', 'participantSrv', 'systemMessages',
            function ($document, $scope, $rootScope, $state, $timeout, $stateParams, desktopSrv, $uibModal, participateSrv, dictionarySrv, cryptography, authSrv, alertSrv, profileSrv, needConfirmSrv, vfSrv,httpSrv,$filter,dialogSrv, proceduresSrv, participantSrv, systemMessages) {
                var ctrl = this;
                $scope.ctrl = ctrl;
                $scope.ctrl.templateUrl = ''; // форма отображения заявки (getTabsVisible)
                $scope.formPart1 = {};
                $scope.formPart2 = {};
                $scope.formPart3 = {};
                $scope.backHistory = backHistoryFn;
                $scope.deleteAttachment = deleteAttachmentFn;
                $scope.deleteAttachmentById = deleteAttachmentByIdFn;
                $scope.deleteAdditional = deleteAdditionalFn;
                $scope.nextTab = nextTabFn;
                $scope.sendCreate = sendCreateFn;
                $scope.sendUpdate = sendUpdateFn;
                $scope.openModal = openModalFn;
                $scope.save = saveFn;
                $scope.selectFiliation = selectFiliationFn;
                $scope.deleted = deleteFn;
                $scope.revoke = revokeFn;
                $scope.changeSum = changeSumFn;
                $scope.checkMSP = checkMSPFn;
                $scope.goBack = goBackFn;
                $scope.cancel = cancelFn;
                $scope.verifyAttachPart1 = verifyAttachPart1Fn;
                $scope.isPercentMode = isPercentModeFn;
                $scope.isEdit = false;
                $scope.isView = false;
                $scope.sendDocument = false;
                $scope.ajaxActive = false; // вместо submitted этот параметр
                $scope.subdivisionItem = null;
                $scope.emailValid = true;
                $scope.emailValidSubdivision = true;
                $scope.phoneValid = true;
                $scope.phoneValidSubdivision = true;
                $scope.additionalArr = [];
                $scope.changeAgreementMode = changeAgreementModeFn;
                $scope.setSort = setSortFn;
                $scope.closeAlertClaimBalance = closeAlertClaimBalanceFn;
                $scope.changeSort = changeSortFn;
                $scope.attachSort = [
                        {column: "created", reverse: false},
                        {column: "created", reverse: false},
                        {column: "created", reverse: false},
                        {column: "created", reverse: false}
                ];
                const mchdErrors = ['427','429'];
                $scope.validationPart1 = {
                    submitted: false
                };
                $scope.validationPart2 = {
                    submitted: false
                };
                $scope.validationPart3 = {
                    submitted: false
                };

                $scope.data = {
                    model: null,
                    availableOptions: [],
                    selectedDivision: {}
                };


                $scope.tabs = [
                    {title: 'Первая часть заявки'},
                    {title: 'Вторая часть заявки'},
                    {title: 'Третья часть заявки'}
                ];
                $scope.agreementModeList = [
                	{name:"на выполнение работ или оказание услуг, указанных в извещении о проведении запроса котировок, на условиях, предусмотренных проектом договора",
                	 value:false},
                	{name:"на поставку товара, который указан в извещении о проведении запроса котировок и в отношении которого в таком извещении содержится указание на товарный знак, на условиях, предусмотренных проектом договора и не подлежащих изменению по результатам проведения запроса котировок",
                	 value:false},
                    {name:"на поставку товара, который указан в извещении о проведении запроса котировок и конкретные показатели которого соответствуют значениям эквивалентности, установленным данным извещением, на условиях, предусмотренных проектом договора",
                	 value:false}
                ];

                /**
                 * Поиск первого не заполненного элемента
                 */
                $scope.focusFirstInvalid = function (params) {
                    var form = params.form,
                        indexTab = params.index;
                    if (angular.isDefined(indexTab)) {
                        $scope.nextTab(indexTab);
                    }
                    if (form.$error && form.$error.required && form.$error.required[0]
                        && form.$error.required[0].$$element && form.$error.required[0].$$element[0]) {
                        desktopSrv.scollTop();
                        setTimeout(function () {
                            form.$error.required[0].$$element[0].focus();
                        }, 100)

                    }
                };
                $scope.getCurrencyPatternByCode = (code) => dictionarySrv.getCurrencyPatternByCode(code);
                ctrl.$onInit = function () {
                    $scope.auth = authSrv.getAuth();
                    $scope.certError = authSrv.isCertificateError;
                    $scope.clf = vfSrv.getVisibleClaimFields($stateParams.procType);
                    $scope.currencyDict = dictionarySrv.getStaticDictByType('currency');
                    $scope.hideOpenParts = (ctrl.createData && !ctrl.createData.error && ctrl.createData.data.success && ctrl.createData.data.result.hideOpenParts) ||
                        (ctrl.updateData && !ctrl.updateData.error && ctrl.updateData.data.success && ctrl.updateData.data.result.hideOpenParts);
                    $scope.attachVisible = vfSrv.getVisiblePartcicipateAttach($stateParams.procType, undefined, undefined, $scope.hideOpenParts);
                    profileSrv.checkAccredSecured().then(function (checkRes) {
                        authSrv.updateOrgAccreditation(checkRes.data.result);
                    });
                    $scope.attachments = [];
                    $scope.model = {
                        correctLength: 0, // файл "Сведения об участнике"
                        showPanel: 0,
                        showPart1: false,
                        showPart2: false,
                        showPart3: false,
                        specVision: false, // UETP-433
                        agreement: false,
                        consentProcessing: false,
                        consentDissemination: false
                    };
                    $scope.stateParams = {
                        procType: $stateParams.procType, // тип закупки
                        procId: $stateParams.procId, // идентификатор закупки
                        lotId: $stateParams.lotId, // идентификатор закупки
                        claimId: $stateParams.claimId  // идентификатор запроса на разъяснение (если открыли на просмотр)
                    };
                    $scope.actionBtns = {
                        isEdit: false,
                        isSave: false,
                        isSign: false,
                        isDelete: false,
                        isCancel: false
                    };
                    $scope.organizationInfo = {
                        inn: null,
                        type: null,
                        msp: null
                    };

                    if (ctrl.createData && !ctrl.createData.error) {
                        participateSrv.setParticipateData(ctrl.createData);
                        if (ctrl.createData.data.success) {
                            $scope.isEdit = false;
                            $scope.participate = ctrl.createData.data.result;
                            _getBtnVisible($scope.participate.actions);
                            if($scope.participate.stage&&$scope.participate.stage=='stage2'){
                              dataModify(true, false);
                            }
                            if (($stateParams.procType==='simplecontest' || ctrl.procedure.commercial || $scope.showProvidedBankGuaranteeCheck() || 'winnerPays'===ctrl.procedure.tariffType) && ctrl.createData.data.result && ctrl.createData.data.result.errorAvailableBalance) {
                            	$scope.alertClaimBalance = ctrl.createData.data.result.errorAvailableBalanceMessage;
                                $scope.sendBlockFlag = true;
                            }
                        } else {
                            dialogSrv.showDialogMsgHtml($scope, ctrl.createData.data.errorMessage, function () {
                                history.back();
                            });
                            return;
                        }
                    } else if (ctrl.createData && ctrl.createData.error) {
                        dialogSrv.showDialogMsgHtml($scope, ctrl.createData.error, function () {
                            history.back();
                        });
                        return;
                    }

                    if (ctrl.updateData) {
                        participateSrv.setParticipateData(ctrl.updateData);
                        if (ctrl.updateData.data.success) {
                            $scope.model.correctLength = 1; // автоматически добавляется файл "Сведения об участнике"
                            $scope.participate = ctrl.updateData.data.result;
                            
                            angular.forEach($scope.participate.part1.applications, function (app) {
                                var orderNumber = $scope.participate.part1.applications.map(function (item) {
                                    return item.id
                                }).indexOf(app.id);
                                app.created = orderNumber;
                            })
                            
                            $scope.applicationsPart1Open = $filter('filter')($scope.participate.part1.applications, {'open': true});
                            $scope.applicationsPart1Close = $scope.hideOpenParts ? $scope.participate.part1.applications : $filter('filter')($scope.participate.part1.applications, {'open': false});
                            
                            _getBtnVisible($scope.participate.actions);
                            // Не проверяем то, что дата подачи уже наступили, если открыто на просмотр или черновик
                            if ($scope.participate.claimEnd && !$scope.isView && $scope.participate.state.id != 'created') {
                                dialogSrv.showDialogMsgHtml($scope, 'Нельзя подать заявку. Дата и время окончания подачи заявок уже наступили.', function () {
                                    history.back();
                                });
                            }
                            $scope.isEdit = true;

                            $scope.model.agreement = true;
                            $scope.model.consentProcessing = !!$scope.participate.part1.consentProcessing;
                            $scope.model.consentDissemination = !!$scope.participate.part1.consentDissemination;
                            $scope.model.providedBankGuarantee = !!$scope.participate.part1.providedBankGuarantee;
                            $scope.participate.part1.consentProcessing = $scope.participate.part1.consentProcessing || (systemMessages.CONSENT_PROCESSING + '.');
                            $scope.participate.part1.consentDissemination = $scope.participate.part1.consentDissemination || (systemMessages.CONSENT_DISSEMINATION + '.');
                            if ($stateParams.successSave) {
                                $scope.alertObjClaim = alertSrv.getSuccessMsgWithBtn("Документ сохранен!");
                            }

                            dataModify(true, false);

                            if (($stateParams.procType==='simplecontest' || ctrl.procedure.commercial || $scope.showProvidedBankGuaranteeCheck() || 'winnerPays'===ctrl.procedure.tariffType) && ctrl.updateData.data.result && ctrl.updateData.data.result.errorAvailableBalance) {
                            	$scope.alertClaimBalance = ctrl.updateData.data.result.errorAvailableBalanceMessage;
                                $scope.sendBlockFlag = true;
                            }
                        } else {
                            dialogSrv.showDialogMsgHtml($scope, ctrl.updateData.data.errorMessage, function () {
                                history.back();
                            });
                            return;
                        }
                    }
                    if ($scope.participate.state && $scope.participate.state.id === 'sent') {
                        $scope.isEdit = true;
                        $scope.sendDocument = true;
                    }
                    if($scope.participate.rebiddingNumber)
                       $scope.attachVisible = vfSrv.getVisiblePartcicipateAttach($stateParams.procType, $scope.participate.stage, $scope.participate.rebiddingNumber, $scope.hideOpenParts);
                    else if($scope.participate.stage)
                       $scope.attachVisible = vfSrv.getVisiblePartcicipateAttach($stateParams.procType, $scope.participate.stage, undefined, $scope.hideOpenParts);

                    $scope.getStageTitle = function () {
                    	if((['requestproposals', 'limitedrequestproposals'].includes($stateParams.procType) && !ctrl.procedure.simplifiedProcedure || $stateParams.procType==='open2stagecontest') && (!$scope.participate.rebiddingNumber || $scope.participate.rebiddingNumber===0)){
                    		return $scope.participate.stage&&$scope.participate.stage==='stage2'?'второй этап':'первый этап';
                    	}
                    };

                    participateSrv.getTabsVisible($scope, $scope.participate.purchase.typeCode);
                    if($scope.participate.participant.orgUL){
                        $scope.organizationInfo.inn = $scope.participate.participant.orgUL.inn;
                        $scope.organizationInfo.type = 'orgUL';
                        $scope.organizationInfo.msp = $scope.participate.participant.orgUL.msp;
                    } else if($scope.participate.participant.orgIP){
                        $scope.organizationInfo.inn = $scope.participate.participant.orgIP.inn;
                        $scope.organizationInfo.type = 'orgIP';
                        $scope.organizationInfo.msp = $scope.participate.participant.orgIP.msp;
                    } else if($scope.participate.participant.orgFL){
                        $scope.organizationInfo.inn = $scope.participate.participant.orgFL.inn;
                        $scope.organizationInfo.type = 'orgFL'
                    } else if($scope.participate.participant.orgNonResident){
                        $scope.organizationInfo.inn = $scope.participate.participant.orgNonResident.code;
                        $scope.organizationInfo.type = 'orgNonResident'
                    }

                    if ($scope.model.showPart3 && $scope.participate.part3 && ($scope.participate.lot.contractSum || $scope.participate.lot.contractSumNoNDS)) {
                        if (!$scope.participate.part3.priceOfferWithoutNDS && $scope.participate.lot.contractSumNoNDS) {
                            $scope.participate.part3.priceOfferWithoutNDS_error = 'Поле не заполнено.'
                        }
                        if (!$scope.participate.part3.priceOfferWithNDS && $scope.participate.lot.contractSum) {
                            $scope.participate.part3.priceOfferWithNDS_error = 'Поле не заполнено.'
                        }
                        if (!$scope.participate.part3.priceOffer && $scope.participate.lot.contractSum && $scope.participate.purchase.typeCode=='requestproposalssmb') {
                            $scope.participate.part3.priceOffer_error = 'Поле не заполнено.'
                        }
                    }


                    participateSrv.getTabsVisible($scope, $scope.participate.purchase.typeCode);

                    if(isPercentModeFn()){
                        $scope.model.showPart3 = true;
                    }
                    else if(!$scope.participate.lot.contractSum && !$scope.participate.lot.contractSumNoNDS){
                       $scope.model.showPart3 = false;
                    }
                    $scope.isOrgDivisionInit = $scope.participate.participant.orgDivision;
                    $scope.model.subdivisionIf = !!$scope.participate.participant.orgDivision;

                    getFilliationDictFn($stateParams.viewOnly ? ctrl.updateData.data.result.id : undefined);

                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    $scope.validationPart1.submitted = true;
                    $scope.validationPart2.submitted = true;
                    $scope.validationPart3.submitted = true;
                    if ($scope.participate.participant.orgDivision) {
                        $scope.data.selectedDivision.fullName = $scope.participate.participant.orgDivision.name;
                    }

                    angular.forEach($scope.participate.participant.extOrgs, function (item) {
                        if (item.msp === null) {
                            item.msp = 'no'
                        }
                        item.mspName = $scope.checkMSP(item.msp)
                    });

                    if(ctrl.procedure){
                        $scope.textAboutPublished= getTextAboutPublished();
                    }

                    if($scope.participate.part3 && !$scope.isView) {
                        $timeout(function (){
                            if(isPercentModeFn()){
                                changeSumFn('percentOffer', $scope.participate.part3.percentOffer,
                                    null, $scope.participate.purchase.procPriceType,
                                    $scope.participate.priceOfferWithoutNDSstage1, $scope.participate.priceOfferBid,
                                    null, $scope.participate);
                            } else {
                                changeSumFn('priceOfferWithoutNDS', $scope.participate.part3.priceOfferWithoutNDS,
                                    $scope.participate.lot.contractSumNoNDS, $scope.participate.purchase.procPriceType,
                                    $scope.participate.priceOfferWithoutNDSstage1, $scope.participate.priceOfferBid,
                                    $scope.formPart3.form ? $scope.formPart3.form.priceOfferWithNDS : $scope.participate.part3.priceOfferWithNDS, $scope.participate);
                                changeSumFn('priceOfferWithNDS', $scope.participate.part3.priceOfferWithNDS,
                                    $scope.participate.lot.contractSum, $scope.participate.purchase.procPriceType,
                                    $scope.participate.priceOfferWithNDSstage1, $scope.participate.priceOfferBid,
                                    $scope.formPart3.form ? $scope.formPart3.form.priceOfferWithoutNDS : $scope.participate.part3.priceOfferWithoutNDS,
                                    $scope.participate, $scope.participate.rebiddingType, $scope.participate.lotState)
                            }
                        },1000);
                    }
                    
                };

                /**
                 * Добавить вложение в первый этап
                 * @param result - ответ с сервера
                 */
                $scope.uploadSuccessAt1Close = function (result) {
                    if (!$scope.participate.part1.applications) {
                        $scope.participate.part1.applications = []
                    }
                    if (!$scope.applicationsPart1Close) {
                    	$scope.applicationsPart1Close = []
                    }
                    var answer = _unixAttachName($scope.getAttachPart1ByType(false), result);
                    if (answer.msgError) {
                       setTimeout(function () {
                        	$scope.ctrl.alertObjAt1 = alertSrv.getErrorMsg(answer.msgError);
                       }, 100);
                    }else{
	                    angular.forEach(answer.successArr, function (item) {
	                        item.open = false;
	                        item.created = $scope.applicationsPart1Close.length > 0 ? Math.max.apply(null, $scope.applicationsPart1Close.map(function(x) {return x.created;})) + 1 : 0;
	                        $scope.participate.part1.applications.push(item);
	                        if(!$scope.hideOpenParts || !$scope.applicationsPart1Close.find(appl=>appl.id===item.id))
                                $scope.applicationsPart1Close.push(item);
	                    })
                        setSortFn(1, true);
                    }
                };
                /**
                 * Добавить вложение в первый этап
                 * @param result - ответ с сервера
                 */
                $scope.uploadSuccessAt1Open = function (result) {
                    if (!$scope.participate.part1.applications) {
                        $scope.participate.part1.applications = []
                    }
                    if (!$scope.applicationsPart1Open) {
                    	$scope.applicationsPart1Open = []
                    }
                    var answer = _unixAttachName($scope.getAttachPart1ByType(true), result);
                    if (answer.msgError) {
                        setTimeout(function () {
                        	 $scope.ctrl.alertObjAt1 = alertSrv.getErrorMsg(answer.msgError);
                        }, 100);
                    }else{
	                    angular.forEach(answer.successArr, function (item) {
	                        item.open = true;
	                        item.created = $scope.applicationsPart1Open.length > 0 ? Math.max.apply(null, $scope.applicationsPart1Open.map(function(x) {return x.created;})) + 1 : 0;
		                    $scope.participate.part1.applications.push(item);
	                        $scope.applicationsPart1Open.push(item);
	                    })
	                    setSortFn(0, true);
                    }
                };
                /**
                 * Добавить вложение во второй этап
                 * @param result - ответ с сервера
                 */
                $scope.uploadSuccessAt2 = function (result) {
                    if (!$scope.participate.part2.applications) {
                        $scope.participate.part2.applications = []
                    }
                    var answer = _unixAttachName($scope.participate.part2.applications, result);
                    if (answer.msgError) {
                        $scope.ctrl.alertObjAt2 = alertSrv.getErrorMsg(answer.msgError);
                    }
                    angular.forEach(answer.successArr, function (item) {
                    	 item.created = $scope.participate.part2.applications.length > 0 ? Math.max.apply(null, $scope.participate.part2.applications.map(function(x) {return x.created;})) + 1 : 0;
		                 $scope.participate.part2.applications.push(item);
                    })
                };
                /**
                 * Добавить вложение в третий этап
                 * @param result - ответ с сервера
                 */
                $scope.uploadSuccessAt3 = function (result) {
                    if (!$scope.participate.part3.applications) {
                        $scope.participate.part3.applications = []
                    }
                    var answer = _unixAttachName($scope.participate.part3.applications, result);
                    if (answer.msgError) {
                        $scope.ctrl.alertObjAt3 = alertSrv.getErrorMsg(answer.msgError);
                    }
                    angular.forEach(answer.successArr, function (item) {
                    	item.created = $scope.participate.part3.applications.length > 0 ? Math.max.apply(null, $scope.participate.part3.applications.map(function(x) {return x.created;})) + 1 : 0;
  		                $scope.participate.part3.applications.push(item);
                    })
                };

                $scope.downloadFile = function (file) {
                    var url,fz = dictionarySrv.getFzbyProcType($stateParams.procType);
                    if(fz==='44'){
                        /**
                         * 44 ФЗ
                         */
                        //todo временно для 44 фз
                    	url = '/fs/file/' + $scope.participate.purchase.typeCode + '/' + $scope.participate.id + '/files/' + file.id;
                    }else  if(fz==='223'){
                        /**
                         * 223 ФЗ
                         */
                        url = '/fs/file/' + $scope.participate.purchase.typeCode + '/' + $scope.participate.id + '/files/' + file.id;
                    }
                    httpSrv.downloadFile(url, file.fileName);
                };
                /**
                 * Уникальные имена файлов во вкладках
                 * @param arrApplications - список который уже есть во вкладке
                 * @param inputApplication - новый список на добавление файла
                 * @returns - вернет текст ошибки если есть и доступные файлы для добавления
                 * @private
                 */
                function _unixAttachName(arrApplications, inputApplication) {
                    var result = {msgError: '', successArr: []};
                    angular.forEach(inputApplication, function (iApp) {
                        var isError = false;
                        if (iApp.fileName ==  $scope.getUnremovableAttachFileName()) {
                            result.msgError += "Файл " + iApp.fileName + " не добавлен. <br />" +
                            "Файл с таким именем не может быть добавлен к этому документу.";
                            isError = true;
                        }
                        angular.forEach(arrApplications, function (app) {
                            if (app.fileName === iApp.fileName) {
                                result.msgError += "Файл " + iApp.fileName + " не добавлен. <br />" +
                                    "Файл с таким именем уже добавлен к этому документу.";
                                isError = true;
                            }
                        })
                        if (isError === false) {
                            result.successArr.push(iApp);
                        }
                    });
                    return result;
                }

                /**
                 * Окно для заполнения организаций
                 * @param modal - идентификатор окна
                 * @param additional  - данные для формы окна
                 */
                function openModalFn(modal, additional, orgsArr, organizationInfo, selectedInn) {
                    $uibModal.open({
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        size: 'lg',
                        templateUrl: modal,
                        backdrop: 'static',
                        controller: ['$scope', '$uibModalInstance', 'dictionarySrv', function ($scope, $uibModalInstance, dictionarySrv) {
                            $scope.alertDismiss = false;
                            $scope.snapshot = [];
                            $scope.error = null;
                            $scope.typeOrg = dictionarySrv.getStaticDictByType('typeOrgDocument');

                            $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                            if ($scope.mspCategory[0].id !== 'no') {
                                $scope.mspCategory.unshift({id: 'no', name: 'Нет'});
                            }


                            dictionarySrv.getDictionaryRemote('okcm', false).then(function () {
                                $scope.dictionary = dictionarySrv.getRemoteData();
                            });
                            $scope.modelView = {countrySelect: {}};
                            if (additional.user) {
                                $scope.extOrg = angular.copy(additional.user);
                                if ($scope.extOrg.type === 'nr') {
                                    $scope.modelView.countrySelect = {
                                        ncm_short: $scope.extOrg.country.name,
                                        kokcm3: $scope.extOrg.country.digitalCode
                                    }
                                }
                            } else {
                                $scope.extOrg = {};
                                $scope.extOrg.type = 'ul';
                                $scope.extOrg.msp = 'no'
                            }

                            $scope.closeModal = function () {
                                $uibModalInstance.close();
                            };
                            $scope.changeTypeUser = function (type) {
                                $scope.extOrg = {};
                                $scope.extOrg.type = type;
                                $scope.extOrg.msp = 'no';
                                $scope.modelView.countrySelect = null;
                                $scope.alertDismiss = false;
                            };
                            $scope.addUser = function (user) {
                                $scope.error = false;
                                angular.forEach($scope.mspCategory, function (category) {
                                    if ($scope.extOrg.msp === category.id) {
                                        $scope.extOrg.mspName = category.name
                                    }
                                });

                                $scope.checkInnUnique(user);
                                if (!$scope.error) {
                                    var obj = $scope.extOrg;
                                    $uibModalInstance.close({data: obj, index: additional.index});
                                }

                            };

                            $scope.onINNChange = function (user){
                                if($scope.extOrg.type !== 'nr')
                                    $scope.checkInnUnique(user);
                                if (!$scope.error) {
                                    participateSrv.getParticipantByINN($stateParams.procType,$scope.extOrg[$scope.extOrg.type == 'nr'?'code':'inn']).then(function successCallback(response) {
                                        if (!response.data.success) {
                                            console.log(response);
                                        }
                                        else if(response.data.result!=null){
                                            var res=response.data.result;
                                            if ($scope.extOrg.type === 'ul') {
                                                angular.extend($scope.extOrg, {
                                                    name:res.fullName,
                                                    kpp:res.kpp,
                                                    okpo:res.okpo,
                                                    ogrn:res.ogrn,
                                                    msp:res.mspType
                                                });
                                            } else if ($scope.extOrg.type === 'ip') {
                                                angular.extend($scope.extOrg, {
                                                    name:res.fullName,
                                                    okpo:res.okpo,
                                                    ogrn:res.ogrn,
                                                    msp:res.mspType
                                                });
                                            } else if ($scope.extOrg.type === 'fl') {
                                                angular.extend($scope.extOrg, {
                                                    name:res.fullName,
                                                    snils:res.snils
                                                });
                                            } else if ($scope.extOrg.type === 'nr') {
                                                angular.extend($scope.extOrg, {
                                                    name:res.fullName,
                                                    country:{
                                                        name:res.regAddress.oksmName,
                                                        digitalCode: res.regAddress.oksmCode
                                                    }
                                                });
                                                $scope.modelView.countrySelect = {
                                                    ncm_short: $scope.extOrg.country.name,
                                                    kokcm3: $scope.extOrg.country.digitalCode
                                                }
                                            }
                                        }

                                    }, function errorCallback(response) {
                                        console.log(response);
                                    });
                                }
                            };
                            $scope.checkInnUnique = function (){
                                $scope.error = false;
                                $scope.alertDismiss = true;
                                if(organizationInfo){
                                    if($scope.extOrg.inn === organizationInfo.inn || $scope.extOrg.code === organizationInfo.inn) {
                                        if(organizationInfo.type === 'orgNonResident'){
                                            $scope.error = 'Введен ИН Вашей организации';
                                            $scope.alertInn = alertSrv.getErrorMsgWithBtn('Введен ИН Вашей организации');
                                        } else {
                                            $scope.error = 'Введен ИНН Вашей организации';
                                            $scope.alertInn = alertSrv.getErrorMsgWithBtn('Введен ИНН Вашей организации');
                                        }

                                    }
                                }
                                if(orgsArr) {
                                    if($scope.extOrg.inn && !additional.user) {
                                        angular.forEach(orgsArr, function (item) {
                                            if(($scope.extOrg.inn === item.inn || $scope.extOrg.inn === item.code) && $scope.extOrg.inn !== selectedInn){
                                                $scope.error = 'Данный участник уже добавлен';
                                                $scope.alertInn = alertSrv.getErrorMsgWithBtn('Данный участник уже добавлен');
                                            }
                                        });
                                    }
                                    if ($scope.extOrg.code && !additional.user) {
                                        angular.forEach(orgsArr, function (item) {
                                            if(($scope.extOrg.code === item.code || $scope.extOrg.code === item.inn) && $scope.extOrg.code !== selectedInn){
                                                $scope.error = 'Данный участник уже добавлен';
                                                $scope.alertInn = alertSrv.getErrorMsgWithBtn('Данный участник уже добавлен');
                                            }
                                        });
                                    }

                                }
                                if(additional.user) {
                                    if($scope.extOrg.inn) {
                                        angular.forEach(orgsArr, function (item) {
                                            if(($scope.extOrg.inn === item.inn || $scope.extOrg.inn === item.code) && (item.code !== additional.user.inn || item.code !== additional.user.code) && $scope.extOrg.inn !== selectedInn){
                                                $scope.error = 'Данный участник уже добавлен';
                                                $scope.alertInn = alertSrv.getErrorMsgWithBtn('Данный участник уже добавлен');
                                            }
                                        });
                                    }
                                    if($scope.extOrg.code) {
                                        angular.forEach(orgsArr, function (item) {
                                            if(($scope.extOrg.code === item.code || $scope.extOrg.code === item.inn) && (item.code !== additional.user.inn || item.code !== additional.user.code) && $scope.extOrg.code !== selectedInn){
                                                $scope.error = 'Данный участник уже добавлен';
                                                $scope.alertInn = alertSrv.getErrorMsgWithBtn('Данный участник уже добавлен');
                                            }
                                        });
                                    }

                                }
                                if (!$scope.error) {
                                    $scope.alertDismiss = false;
                                }
                            };
                            $scope.fillCountry = function () {
                                $scope.extOrg.country = {
                                    name: $scope.modelView.countrySelect.ncm_short,
                                    digitalCode: $scope.modelView.countrySelect.kokcm3
                                }
                            }
                        }]
                    }).result.then(function (obj) {
                        if (obj) {
                            if (!$scope.participate.participant.extOrgs) {
                                $scope.participate.participant.extOrgs = [];
                            }
                            if (angular.isDefined(obj.index)) {
                                $scope.participate.participant.extOrgs[obj.index] = obj.data;
                            } else {
                                $scope.participate.participant.extOrgs.push(obj.data)
                            }
                        }
                    });


                }

                /**
                 * Обвноление данные при получении или отправке
                 * Думаю надо будет переделать моедль данных
                 * @param isInput
                 * @param data
                 */
                function dataModify(isInput, delCreated, data) {
                    var participate = data || $scope.participate;
                    if (participate) {
                        if (isInput) {
                            if (participate.participant.extOrgs) {
                                angular.forEach(participate.participant.extOrgs, function (item) {
                                        if (item.type === 'ul') {
                                            item.inn = item.orgUL.inn;
                                            item.kpp = item.orgUL.kpp;
                                            item.okpo = item.orgUL.okpo;
                                            item.ogrn = item.orgUL.ogrn;
                                            item.msp = item.orgUL.msp;
                                            delete item.orgUL;
                                        } else if (item.type === 'ip') {
                                            item.inn = item.orgIP.inn;
                                            item.okpo = item.orgIP.okpo;
                                            item.ogrn = item.orgIP.ogrn;
                                            item.msp = item.orgIP.msp;
                                            delete item.orgIP;
                                        } else if (item.type === 'fl') {
                                            item.inn = item.orgFL.inn;
                                            item.snils = item.orgFL.snils;
                                            delete item.orgFL;
                                        } else if (item.type === 'nr') {
                                            item.code = item.orgNonResident.code;
                                            item.country = item.orgNonResident.country;
                                            delete item.orgNonResident;
                                        }
                                    }
                                )
                            }
                            if (participate.part2.agreementCode) {
                                changeAgreementModeFn(participate.part2.agreementCode);
                            }
                        } else {
                            if (participate.participant.extOrgs) {
                                angular.forEach(participate.participant.extOrgs, function (item) {
                                        if (item.type === 'ul') {
                                            item.orgUL = {
                                                inn: item.inn,
                                                kpp: item.kpp,
                                                okpo: item.okpo,
                                                ogrn: item.ogrn,
                                                msp: item.msp
                                            };
                                            delete item.inn;
                                            delete item.kpp;
                                            delete item.okpo;
                                            delete item.ogrn;
                                            delete item.msp;
                                        } else if (item.type === 'ip') {
                                            item.orgIP = {
                                                inn: item.inn,
                                                okpo: item.okpo,
                                                ogrn: item.ogrn,
                                                msp: item.msp
                                            };
                                            delete item.inn;
                                            delete item.okpo;
                                            delete item.ogrn;
                                            delete item.msp;
                                        } else if (item.type === 'fl') {
                                            item.orgFL = {
                                                inn: item.inn,
                                                snils: item.snils
                                            };
                                            delete item.inn;
                                            delete item.snils;
                                        } else if (item.type === 'nr') {
                                            item.orgNonResident = {
                                                code: item.code,
                                                country: item.country
                                            };
                                            delete item.code;
                                            delete item.country;
                                        }
                                    }
                                )
                            }
                        }
                        // убираем из вложений лишние теги
                        if(delCreated){
                        	 if (participate.part1 && participate.part1.applications) {
                        		 participate.part1.applications = $filter('orderBy')(participate.part1.applications, 'created');
                                 angular.forEach(participate.part1.applications, function (appl) {
	                                delete appl.created
	                             })
	                        }
	                        if (participate.part2 && participate.part2.applications) {
	                        	participate.part2.applications = $filter('orderBy')(participate.part2.applications, 'created');
	                            angular.forEach(participate.part2.applications, function (appl) {
	                                delete appl.created
	                            })
	                        }
	                        if (participate.part3 && participate.part3.applications) {
	                        	participate.part3.applications = $filter('orderBy')(participate.part3.applications, 'created');
	                            angular.forEach(participate.part3.applications, function (appl) {
	                                delete appl.created
	                            })
	                        }
                        }
                        if($scope.showProvidedBankGuaranteeCheck())
                            participate.part1.providedBankGuarantee = $scope.model.providedBankGuarantee;
                        else
                            delete participate.part1.providedBankGuarantee;
                    }
                }

                /**
                 * Переход между вкладками этапов
                 * @param number - номер вкладки
                 */
                function nextTabFn(number) {
                    $scope.model.showPanel = angular.isDefined(number) ? number : $scope.model.showPanel + 1
                }

                /**
                 * Список филиалов
                 */
                function getFilliationDictFn(claim) {
                    (claim ? profileSrv.loadFiliationListByClaim(claim) : profileSrv.loadFiliationList())
                        .then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.data.availableOptions = response.data.items;
                            } else {
                                $scope.data.availableOptions = [];
                                console.log(response);
                            }
                        })
                }

                /**
                 * Заполнения формы по выбранному филиалу
                 */
                function selectFiliationFn() {
                    const getField = (data) => {
                        return data ? ', ' + data : '';
                    }
                    const fillAddress = (address) => {
                        return address.addressNonResident ? address.addressNonResident :
                            (address.zip + getField(address.regionOKATOName) + getField(address.area) + getField(address.settlement) + getField(address.street) + getField(address.building) + getField(address.subBuilding) + getField(address.office));
                    }
                    const selected = $scope.data.selectedDivision;
                    $scope.participate.participant.orgDivision = {
                        name: selected.fullName,
                        inn: selected.inn,
                        kpp: selected.kpp,
                        ogrn: selected.ogrn,
                        okpo: selected.okpo,
                        email: selected.email,
                        phone: selected.phone,
                        legalAddress: fillAddress(selected.realAddress),
                        postalAddress: selected.regAddress ? fillAddress(selected.regAddress) : fillAddress(selected.realAddress)
                    }
                }

                /**
                 * Проверка валидности формы и очиста данных формы по признакам авктивности чекбоксов
                 */
                function validationData() {

                    if (checkedFormAndMessageValidation()) {
                        if (!$scope.model.subdivisionIf) {
                            $scope.participate.participant.orgDivision = null;
                        }
                        if ($scope.participate.participant.extOrgs && $scope.participate.participant.extOrgs.length === 0) {
                            $scope.participate.participant.extOrgs = null;
                        }
                        return true;
                    } else {
                        return false;
                    }
                }

                /**
                 * Подписать и отправить новый документ
                 */
                function sendCreateFn() {
                    if (validationData()) {
                        if ($scope.auth.certId) {
                            var copy = angular.copy($scope.participate);
                            angular.forEach(copy.participant.extOrgs, function (item) {
                                if (item.msp === 'no') {
                                    delete item.msp
                                }
                                delete item.mspName
                            });
                            dataModify(false, true, copy);
                            participateSrv.sendSignNew($stateParams, copy, $scope.auth.certId).then(function (responseSign) {
                                if (responseSign.data.success) {
	                                participantSrv.addFavorite($stateParams.procId);
                                    updateFormAfterSend().then(function () {
                                        $scope.sendDocument = true;
                                        $scope.isEdit = true;
                                        formValidatorRefresh();
                                        $scope.alertObjClaim = alertSrv.getAlertObj(responseSign, 'Документ отправлен');
                                    })
                                } else {
                                    if(mchdErrors.includes(responseSign?.data?.errorCode)) {
                                        $scope.errorMsg = responseSign.data.errorMessage.replace('\n', '<br/>');
                                        $timeout(() => delete $scope.errorMsg, 7000);
                                    } else
                                        $scope.alertObjClaim = alertSrv.getAlertObj(responseSign);
                                }
                            }, function (text) {
                                $scope.alertObjSign = alertSrv.getErrorMsgWithBtn(text)
                            });
                        } else {
                            $scope.alertObjClaim = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                        }
                    }
                }

                /**
                 * Отправить редактируемый документ
                 */
                function sendUpdateFn() {
                    
                    const fullDateAccredCheck = 'unlimitedprequalification'!==$stateParams.procType && ((['limitedrequestproposals','requestproposals','open2stagecontest'].includes($stateParams.procType) &&
                        $scope.participate.stage && $scope.participate.stage==='stage2') || $scope.participate.rebiddingNumber>0);
                    
                    profileSrv.checkAccredSecured($stateParams.procType, ''+fullDateAccredCheck).then(function (checkRes) {
                        if (!$scope.auth.hasMarketOrgApproval) authSrv.updateOrgAccreditation(checkRes.data.result);
                        $scope.accredited = checkRes.data.result;
                        if(checkRes.data.result){
                            if (validationData() && !$scope.ajaxActive) {
                                $scope.ajaxActive = true;
                                if ($scope.model.showPart3 || $scope.participate.part3) {
                                    delete $scope.participate.part3.priceOfferWithoutNDS_error;
                                    delete $scope.participate.part3.priceOfferWithNDS_error;
                                    delete $scope.participate.part3.priceOffer_error;
                                    desktopSrv.removeKeys($scope.participate, 'deff__');
                                    desktopSrv.removeKeys($scope.participate, '_error');
                                }
                                if ($scope.auth.certId) {
                                    var copy = angular.copy($scope.participate);
                                    angular.forEach(copy.participant.extOrgs, function (item) {
                                        if (item.msp === 'no') {
                                            delete item.msp
                                        }
                                        delete item.mspName
                                    });
                                    dataModify(false, true, copy);
                                    participateSrv.createClaim($stateParams, copy).then(function (response) {
                                        if (response.data.success) {

                                            updateParticipantInfoFileId(response.data);
                                            participateSrv.sendSignUpdate($stateParams, copy, $scope.auth.certId).then(function (responseSign) {
                                                if (responseSign.data.success) {
                                                    updateFormAfterSend().then(function () {
                                                        $scope.ajaxActive = false;
                                                        $scope.sendDocument = true;
                                                        $scope.isEdit = true;
                                                        formValidatorRefresh();
                                                        var msgSent = 'Документ отправлен. Заявке присвоен порядковый номер: ' + responseSign.data.result;
                                                        if((($stateParams.procType=='unlimitedprequalification' || $stateParams.procType=='requestproposals' || $stateParams.procType=='open2stagecontest')&& $scope.participate.stage && $scope.participate.stage=='stage2') || $scope.participate.rebiddingNumber >0){
                                                            msgSent = 'Документ отправлен';
                                                        }
                                                        $scope.alertObjDoc = alertSrv.getAlertObj(responseSign, msgSent);
                                                    })
                                                } else {
                                                    $scope.ajaxActive = false;
                                                    if(['511', '512', '515', '516', '706'].includes(responseSign.data.errorCode)) {
                                                        $scope.alertClaimBalance = $scope.alertClaimBalance || responseSign.data.message;
                                                    } else if(mchdErrors.includes(responseSign.data.errorCode)) {
                                                        $scope.errorMsg = responseSign.data.errorMessage.replace('\n', '<br/>');
                                                        $timeout(() => delete $scope.errorMsg, 7000);
                                                    } else {
                                                    	 $scope.alertObjClaim = alertSrv.getAlertObj(responseSign);
                                                    }
                                                }
                                            }, function (text) {
                                                $scope.ajaxActive = false;
                                                $scope.alertObjSign = alertSrv.getErrorMsgWithBtn(text);
                                            })
                                        } else {
                                            $scope.ajaxActive = false;
                                            if(mchdErrors.includes(response.data.errorCode)) {
                                                $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                                                $timeout(() => delete $scope.errorMsg, 7000);
                                            } else {
                                                $scope.alertObjClaim = alertSrv.getAlertObj(response);
                                            }
                                        }
                                    })
                                } else {
                                    $scope.ajaxActive = false;
                                    $scope.alertObjClaim = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                                }
                            }
                        } else {
                            $scope.alertObjClaim = alertSrv.getErrorMsgWithBtn('Отправка заявки невозможна! В соответствии с Регламентом, для Участников с отсутствующей аккредитацией, а также когда до даты окончания срока аккредитации осталось менее трёх месяцев, подача новых заявок блокируется. ', 'Перейти', goToAccreditation, 'profile');
                        }


                    });
                }

                /**
                 * Переход в профиль, вкладка "аккредитация"
                 */

                function goToAccreditation () {
                    $state.go("profile",({activeProfileTab:'accreditation'}))
                }

                /**
                 * Сохранить документ
                 */
                function saveFn() {

                    if (validationData() && !$scope.ajaxActive) {
                        $scope.ajaxActive = true;
                        if ($scope.model.showPart3 || $scope.participate.part3) {
                            delete $scope.participate.part3.priceOfferWithoutNDS_error;
                            delete $scope.participate.part3.priceOfferWithNDS_error;
                            delete $scope.participate.part3.priceOffer_error;
                            desktopSrv.removeKeys($scope.participate, 'deff__');
                            desktopSrv.removeKeys($scope.participate, '_error');
                        }

                        const copy = angular.copy($scope.participate);

                        angular.forEach(copy.participant.extOrgs, function (item) {
                            if (item.msp === 'no') {
                                delete item.msp
                            }
                            delete item.mspName
                        });

                        dataModify(false, true, copy);

                        participateSrv.createClaim($stateParams, copy).then(function (response) {
                            if (response.data.success) {
                                if (copy.id) {
                                    $scope.alertObjClaim = alertSrv.getAlertObj(response, "Документ сохранен!");
                                }
                                participantSrv.addFavorite($stateParams.procId);
                                var param = $scope.stateParams;
                                $state.go('openParticipate', {
                                    procType: param.procType,
                                    procId: param.procId,
                                    lotId: param.lotId,
                                    claimId: response.data.result,
                                    successSave: true
                                }, {location: 'replace',reload: !!copy.id})


                            } else {
                                $scope.ajaxActive = false;
                                if(mchdErrors.includes(response.data.errorCode)) {
                                    $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                                    $timeout(() => delete $scope.errorMsg, 7000);
                                } else {
                                    $scope.alertObjClaim = alertSrv.getAlertObj(response);
                                }
                            }
                        })
                    }
                }

                /**
                 * Проверка форм и вывод сообщений если есть ошибки
                 */
                function checkedFormAndMessageValidation() {
                    if ($scope.model.showPart1) {
                        if ($scope.formPart1.form.$invalid) {
                            $scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме. ');
                            nextTabFn(0);
                            return false;
                        }
                        if ($scope.participate.part1.applications) {
                            if (!verifyAttachPart1Fn(true)) {
                                nextTabFn(0);
                                return false;
                            }
                        }
                    }
                    if ($scope.model.showPart2) {
                        if ($scope.formPart2.form.$invalid||($scope.clf.agreement&&!$scope.participate.part2.agreement)) {
                            $scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме. ');
                            nextTabFn(1);
                            return false;
                        }
                        if (!$scope.model.specVision && (!$scope.participate.part2.applications || $scope.participate.part2.applications.length === 0)) {
                            $scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо добавить вложение. ');
                            nextTabFn(1);
                            return false;
                        }
                    }
                    if ($scope.model.showPart3) {
                        if ($scope.formPart3.form.$invalid) {
                            $scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме. ');
                            nextTabFn(2);
                            return false;
                        }
                        //по UETP-741, п.2
                        if ($scope.participate.part3.priceOfferWithNDS_error || 
                        	$scope.participate.part3.priceOfferWithoutNDS_error ||
                        	($scope.participate.part3.priceOffer_error&&$scope.participate.purchase.typeCode=='requestproposalssmb') ||
                            (isPercentModeFn() && $scope.participate.part3.percentOffer_error)) {
                        	$scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме. ');
                        	return false;
                        }
                        if (!$scope.model.specVision && $scope.participate.purchase.typeCode!='requestquotationssmb'&&(!$scope.participate.part3.applications || $scope.participate.part3.applications.length === 0)) {
                            $scope.alertObjClaim = alertSrv.getErrorMsg('Необходимо добавить вложение. ');
                            nextTabFn(2);
                            return false;
                        }
                    }
                    return true;


                }

                /**
                 * Сброс валидации
                 */
                function formValidatorRefresh() {
                    if ($scope.model.showPart1) {
                        $scope.formPart1.form.$setPristine();
                    }
                    $scope.formPart2.form.$setPristine();
                    if ($scope.model.showPart3) {
                        $scope.formPart3.form.$setPristine();
                    }
                }

                /**
                 * Активация кнопок в зависимости от разрешнных действий сервера
                 * @param actions - действия которые можно выпонить с документом
                 * @private
                 */
                function _getBtnVisible(actions) {
                    if ($scope.participate.id) {
                        if (actions && actions.length > 0) {
                            angular.forEach(actions, function (action) {
                                if (action.id.indexOf('editClaim') >= 0) {
                                    $scope.actionBtns.isEdit = true;
                                    $scope.actionBtns.isSave = true;
                                } else if (action.id.indexOf('sendClaim') >= 0) {
                                    $scope.actionBtns.isSign = true;
                                    $scope.actionBtns.isSave = true;

                                }
                                else if (action.id.indexOf('deleteClaim') >= 0) {
                                    $scope.actionBtns.isDelete = true;
                                }
                                else if (action.id.indexOf('revokeClaim') >= 0) {
                                    $scope.actionBtns.isCancel = true;
                                    $scope.isView = true
                                }
                            })
                        } else {
                            $scope.isView = true
                        }
                    } else {
                        $scope.actionBtns.isSave = true;
                    }
                }
                /**
                 * Сброс кнопок
                 */
                function resetActionsBtns() {
                    angular.forEach($scope.actionBtns, function (value, key) {
                        $scope.actionBtns[key] = false;
                    })
                }

                /**
                 * Обновление формы после отправки
                 * @returns {PromiseLike<T> | Promise<T> | *}
                 */
                function updateFormAfterSend() {
                    resetActionsBtns();
                    return participateSrv.getClaim($scope.stateParams).then(function (response) {
                        _getBtnVisible(response.data.result.actions);
                        $scope.applicationsPart1Open = $filter('filter')(response.data.result.part1.applications, {'open': true});
                        $scope.applicationsPart1Close = $filter('filter')(response.data.result.part1.applications, {'open': false});
                        return Promise.resolve(response);
                    }, function (reason) {
                        if(mchdErrors.includes(reason?.data?.errorCode)) {
                            $scope.errorMsg = reason.data.errorMessage.replace('\n', '<br/>');
                            $timeout(() => delete $scope.errorMsg, 7000);
                        } else {
                            $scope.alertObjClaim = alertSrv.getAlertObj(reason);
                        }
                        return Promise.reject(reason);
                    })
                }

                /**
                 * Обновление id файла сведений об участнике после сохранения
                 */
                function updateParticipantInfoFileId(data) {
                	if(data.message && $scope.participate && $scope.participate.part1){
                	     angular.forEach($scope.participate.part1.applications, function (item) {
                            if (item.fileName === $scope.getUnremovableAttachFileName()) {
                            	item.id = data.message;
                            }
                        });
                	}
                	
                }
                /**
                 * Возврат к предыдущему состоянию (url)
                 */
                function backHistoryFn() {
                    history.back();
                }

                /**
                 * Удалить документ
                 */
                function deleteFn() {
                    needConfirmSrv.call(deleteItemFn, [], 'Документ будет удален. Продолжить?');

                }

                function deleteItemFn() {
                    participateSrv.deleteClaim($scope.stateParams).then(function (response) {
                        if (response.data.success) {
                            $scope.alertObjClaim = alertSrv.getSuccessMsgWithBtn('Документ успешно удален!');
                            resetActionsBtns();
                            setTimeout(function () {
                                $state.go('myProcedures');
                            }, 2000)
                        } else {
                            if(mchdErrors.includes(response?.data?.errorCode)) {
                                $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                                $timeout(() => delete $scope.errorMsg, 7000);
                            } else {
                                $scope.alertObjClaim = alertSrv.getAlertObj(response);
                            }
                        }
                    })
                }

                /**
                 * Удаление вложений из этапов
                 * @param index - номер записи
                 * @param at - тип этапа
                 */
                function deleteAttachmentFn(index, at) {
                    needConfirmSrv.call(sliceAttachFn, [index, at], 'Вложение будет удалено. Продолжить?');
                }
                /**
                 * Удаление вложений из этапов
                 * @param id - записи
                 * @param at - тип этапа
                 */
                function deleteAttachmentByIdFn(id, at, index) {
                    needConfirmSrv.call(sliceAttachByIdFn, [id, at, index], 'Вложение будет удалено. Продолжить?');
                }

                function sliceAttachFn(index, at) {
                    if (at === 'at1') {
                        $scope.participate.part1.applications.splice(index, 1);
                    } else if (at === 'at2') {
                        $scope.participate.part2.applications.splice(index, 1);
                    } else if (at === 'at3') {
                        $scope.participate.part3.applications.splice(index, 1);
                    }
                }
                function sliceAttachByIdFn(id, at, index) {
                    var indexPart1 = $scope.participate.part1.applications.map(function (item) {
                        return item.id
                    }).indexOf(id);
                    $scope.participate.part1.applications.splice(indexPart1, 1);
                    if(!$scope.hideOpenParts) {
                        if (at === 'at1open') {
                            $scope.applicationsPart1Open.splice(index, 1);
                        } else if (at === 'at1close') {
                            $scope.applicationsPart1Close.splice(index, 1);
                        }
                    }
                }

                /**
                 * Удаление организаций из таблицы
                 * @param index - номер записи
                 */
                function deleteAdditionalFn(index) {
                    needConfirmSrv.call(sliceAdditionalFn, [index], 'Лицо, выступающее на стороне одного участника, будет удалено. Продолжить?');
                }

                function sliceAdditionalFn(index) {
                    $scope.participate.participant.extOrgs.splice(index, 1);
                }

                function revokeFn() {
                    if (!$scope.ajaxActive) {
                        $scope.ajaxActive = true;
                        if ($scope.auth.certId) {
                            participateSrv.sendRevoke($scope.stateParams, $scope.auth.certId).then(function (response) {
                                if (response.data.success) {
                                    resetActionsBtns();
                                    $scope.alertObjClaim = alertSrv.getSuccessMsgWithBtn('Документ отозван!');
                                } else {
                                    $scope.ajaxActive = false;
                                    if(mchdErrors.includes(response?.data?.errorCode)) {
                                        $scope.errorMsg = response.data.errorMessage.replace('\n', '<br/>');
                                        $timeout(() => delete $scope.errorMsg, 7000);
                                    } else {
                                        $scope.alertObjClaim = alertSrv.getAlertObj(response);
                                    }
                                }
                            }, function (text) {
                                $scope.ajaxActive = false;
                                $scope.alertObjClaim = alertSrv.getErrorMsgWithBtn(text)
                            })
                        } else {
                            $scope.ajaxActive = false;
                            $scope.alertObjClaim = alertSrv.getErrorMsgWithBtn('У вас не установлен сертификат.');
                        }
                    }

                }

                function changeSumFn(name, value, startSum, procPriceType, valueStage1, valueBid, elemNext, participate, rebiddingType, lotState) {

                    actualizePricesForSimpleContest44();

                    if(isPercentModeFn()){
                        if($scope.participate.lot.percentMode === "MAX_PERCENT"){
                            if (isNaN(value)) {
                                $scope.participate.part3[name] = null;
                                $scope.participate.part3[name + '_error'] = 'Поле не заполнено.'
                            } else if (participate.rebiddingNumber === 0 && $scope.participate.lot.startPercent < value * 1) {
                                $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент не должен превышать начальный процент/коэффициент по лоту.';
                            } else if (participate.rebiddingNumber > 0 && participate.percentOfferPrevStage && (participate.percentOfferPrevStage * 1 <= value * 1)) {
                                $scope.participate.part3[name + '_error'] = `Предложенный процент/коэффициент должен быть ниже процента/коэффициента, предложенного на прежних этапах (${$filter('percent')(participate.percentOfferPrevStage)}).`
                            } else if (participate.rebiddingNumber === 0 && valueStage1 && (valueStage1 * 1 < value * 1)) {
                                $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент по лоту не должен превышать процент/коэффициент, предложенный на первом этапе (' + $filter('percent')(valueStage1) + ')'
                            } else if (valueBid) {

                                if (value * 1 !== valueBid * 1) {
                                    $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент должен быть равен лучшему значению, поданному в ходе переторжки'
                                } else {
                                    $scope.participate.part3[name + '_error'] = null;
                                }
                            } else
                                $scope.participate.part3[name + '_error'] = null;
                        } else {
                            if (isNaN(value)) {
                                $scope.participate.part3[name] = null;
                                $scope.participate.part3[name + '_error'] = 'Поле не заполнено.'
                            } else if (participate.rebiddingNumber === 0 && $scope.participate.lot.startPercent > value * 1) {
                                $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент не должен быть ниже начального процента/коэффициента по лоту.';
                            } else if (participate.rebiddingNumber > 0 && participate.percentOfferPrevStage && (participate.percentOfferPrevStage * 1 >= value * 1)) {
                                $scope.participate.part3[name + '_error'] = `Предложенный процент/коэффициент должен быть выше процента/коэффициента, предложенного на прежних этапах (${$filter('percent')(participate.percentOfferPrevStage)}).`
                            } else if (participate.rebiddingNumber === 0 && valueStage1 && (valueStage1 * 1 > value * 1)) {
                                $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент по лоту не должен быть ниже процента/коэффициента, предложенного на первом этапе (' + $filter('percent')(valueStage1) + ')'
                            } else if (valueBid) {
                                if (value * 1 !== valueBid * 1) {
                                    $scope.participate.part3[name + '_error'] = 'Предложенный процент/коэффициент должен быть равен лучшему значению, поданному в ходе переторжки'
                                } else {
                                    $scope.participate.part3[name + '_error'] = null;
                                }
                            } else
                                $scope.participate.part3[name + '_error'] = null;
                        }
                    } else {

                        if ($scope.isSimpleContest() && $scope.isNomenclaturePrice()) {
                            if (isNaN(value)) {
                                $scope.participate.part3[name] = null;
                                $scope.participate.part3[name + '_error'] = 'Поле не заполнено.'
                            } else if ($scope.participate.part3.priceOfferWithoutNDS * 1 > $scope.participate.part3.priceOfferWithNDS * 1) {
                                $scope.participate.part3.priceOfferWithNDS_error = 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.'
                            } else
                                $scope.participate.part3[name + '_error'] = null;

                        } else if (rebiddingType && lotState && rebiddingType == 'online' && lotState == 'postRebidding') {
                            if (value == 0) {
                                $scope.participate.part3[name + '_error'] = 'Цена должна быть больше 0.'
                            } else if (isNaN(value)) {
                                $scope.participate.part3[name] = null;
                                $scope.participate.part3[name + '_error'] = 'Поле не заполнено.'
                            } else if ($scope.participate.part3.priceOfferWithoutNDS * 1 > $scope.participate.part3.priceOfferWithNDS * 1) {
                                $scope.participate.part3.priceOfferWithNDS_error = 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.'
                            } else if (participate.rebiddingNumber >= 0 && name == 'priceOfferWithNDS' && participate.priceOfferWithNDSstage1 && (participate.priceOfferWithNDSstage1 * 1 <= value * 1) && procPriceType === 'MAX_PRICE') {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту должна быть меньше цены, предложенной на прежних этапах (' + getPrice(participate.priceOfferWithNDSstage1, participate) + ')'
                            } else
                                $scope.participate.part3[name + '_error'] = null;

                        } else {

                            if (value == 0) {
                                $scope.participate.part3[name + '_error'] = 'Цена должна быть больше 0.'
                            } else if (isNaN(value)) {
                                $scope.participate.part3[name] = null;
                                $scope.participate.part3[name + '_error'] = 'Поле не заполнено.'
                            } else if (participate.rebiddingNumber == 0 && valueStage1 && (valueStage1 * 1 < value * 1) && procPriceType === 'MAX_PRICE') {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту не должна превышать предложенную цену по первому этапу (' + getPrice(valueStage1, participate) + ')'
                            } else if (participate.rebiddingNumber == 0 && valueStage1 && (valueStage1 * 1 > value * 1) && procPriceType === 'MIN_PRICE') {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту не должна быть меньше предложенной цены по первому этапу (' + getPrice(valueStage1, participate) + ')'
                            } else if (participate.rebiddingNumber > 0 && name == 'priceOfferWithoutNDS' && participate.priceOfferWithoutNDSstage1 && (participate.priceOfferWithoutNDSstage1 * 1 <= value * 1) && procPriceType === 'MAX_PRICE') {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту должна быть меньше цены, предложенной на прежних этапах (' + getPrice(participate.priceOfferWithoutNDSstage1, participate) + ')'
                            } else if (participate.rebiddingNumber > 0 && name == 'priceOfferWithNDS' && participate.priceOfferWithNDSstage1 && (participate.priceOfferWithNDSstage1 * 1 <= value * 1) && procPriceType === 'MAX_PRICE') {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту должна быть меньше цены, предложенной на прежних этапах (' + getPrice(participate.priceOfferWithNDSstage1, participate) + ')'
                            } else if (participate.rebiddingNumber > 0 && name == 'priceOfferWithoutNDS' && participate.priceOfferWithoutNDSstage1 && procPriceType === 'MIN_PRICE' && (participate.priceOfferWithoutNDSstage1 * 1 >= value * 1)) {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту должна быть больше цены, предложенной на прежних этапах (' + getPrice(participate.priceOfferWithoutNDSstage1, participate) + ')'
                            } else if (participate.rebiddingNumber > 0 && name == 'priceOfferWithNDS' && participate.priceOfferWithNDSstage1 && procPriceType === 'MIN_PRICE' && (participate.priceOfferWithNDSstage1 * 1 >= value * 1)) {
                                $scope.participate.part3[name + '_error'] = 'Предложенная цена по лоту должна быть больше цены, предложенной на прежних этапах (' + getPrice(participate.priceOfferWithNDSstage1, participate) + ')'
                            } else if ((startSum * 1 < value * 1) && procPriceType === 'MAX_PRICE') {
                                $scope.participate.part3[name + '_error'] = `Предложенная цена по лоту не должна превышать начальную цену ${$scope.isLotPriceForItem()?"за единицу товара":"договора по лоту"}.`
                            } else if ((startSum * 1 > value * 1) && procPriceType === 'MIN_PRICE') {
                                $scope.participate.part3[name + '_error'] = `Предложенная цена по лоту не должна быть меньше начальной цены ${$scope.isLotPriceForItem()?"за единицу товара":"договора по лоту"}.`
                            } else if (valueBid) {

                                if (value * 1 != valueBid * 1 && name == 'priceOfferWithoutNDS') {
                                    $scope.participate.part3[name + '_error'] = 'Цена без НДС должна быть равна лучшей цене, поданной в ходе переторжки'
                                } else if (value * 1 != valueBid * 1 && elemNext.$modelValue * 1 != valueBid * 1) {
                                    $scope.participate.part3[name + '_error'] = 'Цена без НДС должна быть равна лучшей цене, поданной в ходе переторжки'
                                } else {
                                    $scope.participate.part3[name + '_error'] = null;
                                    $scope.participate.part3[elemNext.$name + '_error'] = null;
                                    if (elemNext.$modelValue * 1 == 0) {
                                        $scope.participate.part3[elemNext.$name + '_error'] = 'Цена должна быть больше 0.'
                                    }
                                    if ($scope.participate.part3.priceOfferWithoutNDS * 1 > $scope.participate.part3.priceOfferWithNDS * 1) {
                                        $scope.participate.part3.priceOfferWithoutNDS_error = 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.'
                                    }
                                }
                            } else {
                                $scope.participate.part3[name + '_error'] = null;
                                if (!$scope.participate.part3.priceOfferWithoutNDS_error || $scope.participate.part3.priceOfferWithoutNDS_error === 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.') {
                                    if ($scope.participate.part3.priceOfferWithoutNDS * 1 > $scope.participate.part3.priceOfferWithNDS * 1) {
                                        $scope.participate.part3.priceOfferWithoutNDS_error = 'Предложенная цена по лоту без НДС не должна превышать предложенную цену по лоту с НДС.'
                                    } else {
                                        $scope.participate.part3.priceOfferWithoutNDS_error = null;
                                    }
                                }

                                actualizePricesForSimpleContest44();
                            }
                        }
                    }
                    function getPrice(price, participate) {
                        return $filter('currency')(price, $scope.getCurrencySign(participate.lot.currency.digitalCode).replace(' ', ''));
                    }
                }

                function actualizePricesForSimpleContest44(){
                    if(!isPercentModeFn() && $scope.isSimpleContest44()){
                        $scope.participate.part3.priceOfferWithoutNDS = $scope.participate.part3.priceOfferWithNDS;
                        $scope.participate.part3.priceOfferWithoutNDS_error = $scope.participate.part3.priceOfferWithNDS_error;
                    }
                }

                function checkMSPFn(name) {
                    var msp;
                    angular.forEach($scope.mspCategory, function (item) {
                        if (name === item.id) {
                            msp = item.name
                        }
                    });
                    return msp
                }
                
                function changeAgreementModeFn(num) {
                	$scope.agreementModeList[0].value = false;
                	$scope.agreementModeList[1].value = false;
                	$scope.agreementModeList[2].value = false;
                	$scope.agreementModeList[num].value = true;
                	$scope.participate.part2.agreement=$scope.agreementModeList[num].name;
                	$scope.participate.part2.agreementCode=num;
                }

                function goBackFn() {
                    history.back();
                }

                function cancelFn() {
                    if (angular.isDefined($scope.formPart1.form) && $scope.formPart1.form.$dirty ||
                        angular.isDefined($scope.formPart2.form) && $scope.formPart2.form.$dirty ||
                        angular.isDefined($scope.formPart3.form) && $scope.formPart3.form.$dirty) {
                        needConfirmSrv.call(goBackFn, [], 'Внесенные изменения сохранены не будут');
                    } else {
                        goBackFn();
                    }
                }
                function verifyAttachPart1Fn(isViewMsgError) {
                    var result = true;
                    if ($scope.attachVisible.isReqCloseAttach && $scope.attachVisible.attach) {
                        var closeAtt = $scope.getAttachPart1ByTypeRemovable(false);
                        result = closeAtt.length > 0
                    }
                    if ($scope.attachVisible.isReqOpenAttach && $scope.attachVisible.openAttach) {
                        var openAtt = $scope.getAttachPart1ByTypeRemovable(true);
                        result = (result === false) ? false : openAtt.length > 0
                    }
                    if (isViewMsgError && result === false) {
                        $scope.alertObjClaim = 'Необходимо добавить вложение.';
                    }
                    return result
                }
                $scope.getAttachPart1ByType = function(isOpen) {
                    if (!$scope.participate.part1.applications) {
                        $scope.participate.part1.applications = []
                    }
                    if($scope.hideOpenParts)
                        return $scope.participate.part1.applications;

                    return $scope.participate.part1.applications.filter(function (item) {
                        return item.open === isOpen;
                    });
                };

                $scope.getUnremovableAttachFileName = function(){
                    return 'Сведения об участнике.pdf';
                };

                // не учитываем Сведения об участнике.pdf
                $scope.getAttachPart1ByTypeRemovable = function(isOpen) {
                    if (!$scope.participate.part1.applications) {
                        $scope.participate.part1.applications = []
                    }
                    if($scope.hideOpenParts)
                        return $scope.participate.part1.applications.filter(function (item) {
                            return item.fileName !== $scope.getUnremovableAttachFileName();
                        });
                    return $scope.participate.part1.applications.filter(function (item) {
                        return item.open === isOpen && item.fileName !== $scope.getUnremovableAttachFileName();
                    });
                };

                const getTextAboutPublished = function () {
                    const customer = ctrl.procedure.customer;
                    const rebiddingNumber = $scope.participate.rebiddingNumber;
                    const typeCode = $scope.participate.purchase.typeCode;
                    let text = '';

                    const qualificationCondition = ['223','commercial'].includes(ctrl.procedure.section) && ['limitedcontestqualification', 'prequalification', 'unlimitedprequalification'].includes(typeCode);

                    if(qualificationCondition || (['223','commercial'].includes(ctrl.procedure.section) && !['openauction', 'limitedauction', 'simplecontest', 'prequalification', 'unlimitedprequalification'].includes(typeCode) && $scope.hideOpenParts)) {
                        text = 'На этой вкладке Вам необходимо приложить файлы заявки. Состав файлов определяется документацией о процедуре.';
                        if(qualificationCondition || (!(rebiddingNumber || ['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode) && $scope.participate.stage==='stage2')))
                            text += '<br>После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';
                        return text;
                    } else if(typeCode ==='unlimitedprequalification') return '';

                    if(customer.customerType){
                        if((customer.customerType === 'head' && customer.shortName === 'ОАО «РЖД»') || (customer.customerType === 'division' && customer.parent.shortName === 'ОАО «РЖД»')) {
                            if(ctrl.procedure.commercial){
                                if(['opencontest', 'limitedcontest', 'limitedrequestquotations', 'requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode))
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой и закрытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                                if(typeCode ==='requestquotations')
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                                if(['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode) && $scope.participate.stage==='stage2')
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                                if(rebiddingNumber > 0)
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                            } else {
                                if(['opencontest', 'limitedcontest', 'limitedrequestquotations', 'requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode))
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой и закрытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                                if(typeCode ==='requestquotations')
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                                if(['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode) && $scope.participate.stage==='stage2')
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке. <br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                                if(rebiddingNumber > 0)
                                    text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке. <br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                            }

                        } else if ((customer.customerType === 'dzo' || (customer.customerType === 'head' && customer.shortName !== 'ОАО «РЖД»')) && customer.datemovetonewconvention){

                            if(['opencontest', 'limitedcontest', 'limitedrequestquotations', 'requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode))
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой и закрытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                            if(typeCode ==='requestquotations')
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                            if(['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode) && $scope.participate.stage==='stage2')
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке. <br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                            if(rebiddingNumber > 0)
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке. <br> Файлы, добавленные в раздел "Открытая часть", после завершения закупки станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                        } else {
                            if(['opencontest', 'limitedcontest', 'limitedrequestquotations', 'requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode))
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой и закрытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                            if(typeCode ==='requestquotations')
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.<br><br> После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';

                            if(['requestproposals', 'limitedrequestproposals', 'open2stagecontest'].includes(typeCode) && $scope.participate.stage==='stage2')
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                            if(rebiddingNumber > 0)
                                text = 'На этой вкладке Вам необходимо приложить файлы открытой части заявки. Состав архивов и обязательность вложения открытой и закрытой части заявки определяются документацией о закупке.<br> Файлы, добавленные в раздел "Открытая часть", после окончания срока подачи заявок станут общедоступными и отобразятся на странице закупки. Файлы, добавленные в раздел "Закрытая часть", после окончания срока подачи заявок станут доступны только организатору.';

                        }
                        if(typeCode==='simplecontest')
                        {
                            const date = ['head', 'dzo'].includes(customer.customerType) ? (ctrl.procedure.commercial ? customer.hideCommercialOpenPartAfter : customer.hide223OpenPartAfter) :
                               ('division'===customer.customerType ? (ctrl.procedure.commercial ? customer.parent.hideCommercialOpenPartAfter : customer.parent.hide223OpenPartAfter) : undefined);

                            text = 'На этой вкладке Вы можете приложить файлы заявки. Состав файлов определяется документацией о закупке.<br>';
                            if('44'===ctrl.procedure.section || !(date && ctrl.procedure.created>=date))
                                text += (ctrl.procedure.commercial ? 'Все добавленные файлы после наступления срока окончания подачи заявок станут общедоступными и отобразятся на странице закупки.<br><br>' :
                                        'Все добавленные файлы после завершения закупки станут общедоступными и отобразятся на странице закупки.<br><br>');
                            else
                                text += '<br>';
                            text += 'После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.';
                        } else if( ['openauction', 'limitedauction', 'saleauction', 'openpublicoffer'].includes(typeCode)){
                            text = 'На этой вкладке Вам необходимо приложить файлы заявки. Состав файлов определяется документацией о процедуре.<br>' +
                                'После сохранения заявки автоматически будет сформирован файл "Сведения об участнике.pdf" с данными, указанными в заявке. При необходимости проверьте его.'
                        }

                        return text;
                    }
                };
                function setSortFn(num, withoutStyle) {
                 	var orderBy = localStorage.getItem("claimAttachOrderBy_" + num);
                 	if(!orderBy) {
                 		orderBy = "created";
                 	}
                	var orderAsc = (localStorage.getItem("claimAttachOrderAsc_" + num) == 'true');
	                 
                	if(!withoutStyle){
	            	 	[].forEach.call(document.querySelectorAll(".num" + num + ".sortColumn"), function (column) {
	 	                    column.classList.remove("sortDesc");
	 	                    column.classList.remove("sortAsc");
	 	                });
	                	[].forEach.call(document.querySelectorAll(".num" + num + "[st-sort='" + orderBy + "']"), function (column) {
	 	                     column.classList.add(orderAsc ? "sortAsc" : "sortDesc");
	 	                });
                	}
                    var collator = new Intl.Collator('en', {numeric: true, sensitivity: 'variant', caseFirst: 'upper'});
                   
                    var applicationsSorted;
                	
                    if(num==0){
                    	applicationsSorted = $scope.applicationsPart1Open;
                    }else if(num==1){
                    	applicationsSorted = $scope.applicationsPart1Close;
                    }else{
                    	applicationsSorted = $scope.participate['part' + num].applications;
                    }
                    
                    if(orderAsc){
                    	applicationsSorted.sort(function(a, b) { return collator.compare(b[orderBy], a[orderBy]) });
                    }else{
	                	applicationsSorted.sort(function(a, b) { return collator.compare(a[orderBy], b[orderBy]) });
	                }
                }
               
                
                function changeSortFn($event, num) {
                    var th = $event.currentTarget;
                    var orderBy  = th.getAttribute('st-sort');
                    var orderAsc = th.classList.contains("sortAsc");
	                [].forEach.call(document.querySelectorAll(".num" + num + ".sortColumn"), function (column) {
	                    column.classList.remove("sortDesc");
	                    column.classList.remove("sortAsc");
	                });
	                orderAsc = !orderAsc;
	                th.classList.add(orderAsc ? "sortAsc" : "sortDesc");
	                if (orderBy) {
	                    $scope.attachSort[num].column = orderBy;
	                    $scope.attachSort[num].reverse = orderAsc;
	                    
	                    var collator = new Intl.Collator('en', {numeric: true, sensitivity: 'variant', caseFirst: 'upper'});
	                    
	                    var applicationsSorted;
	                	
	                    if(num==0){
	                    	applicationsSorted = $scope.applicationsPart1Open;
	                    }else if(num==1){
	                    	applicationsSorted = $scope.applicationsPart1Close;
	                    }else{
	                    	applicationsSorted = $scope.participate['part' + num].applications;
	                    }
	                    
	                    if(orderAsc){
	                    	applicationsSorted.sort(function(a, b) { return collator.compare(b[orderBy], a[orderBy]) });
                        }else{
		                	applicationsSorted.sort(function(a, b) { return collator.compare(a[orderBy], b[orderBy]) });
		                }
	                    
 	                    localStorage.setItem("claimAttachOrderBy_" + num, orderBy);
	                    localStorage.setItem("claimAttachOrderAsc_" + num, orderAsc);
                    }
                }
                   
                $scope.getOrderNumber = function(applications, id, num){
                	 var applicationsSorted = $filter('orderBy')(applications, 'created');
                	 if(!$scope.hideOpenParts && (num == 0 || num == 1)){
                	    applicationsSorted = $filter('filter')(applicationsSorted, {'open': num == 0 ? true : false});
                	 }
                	 var orderNumber = applicationsSorted.map(function (item) {
                         return item.id
                     }).indexOf(id);
                	 return orderNumber + 1;
                };

                $scope.changePurchaseItem = function(currentInput,inputNDS,inputNoNDS,purchaseItem,initSumNDS,initSumNoNDS){
                    if($scope.isSimpleContest44())
                        inputNoNDS = inputNDS;
                    var checkResult = $scope.checkCurrentField(currentInput,inputNDS,inputNoNDS,purchaseItem,initSumNDS,initSumNoNDS);
                    if(checkResult==null){
                        if($scope.isSimpleContest44())
                            purchaseItem.offerItemPriceNoNDS = purchaseItem.offerItemPriceWithNDS;
                        
                        if(currentInput==='offerItemPriceWithNDS') {
                            purchaseItem.offerItemPriceWithNDS_error = null;
                        } else {
                            purchaseItem.offerItemPriceNoNDS_error = null;
                        }

                        var pairCheck = $scope.checkCurrentField(currentInput==='offerItemPriceWithNDS' ? 'offerItemPriceNoNDS' : 'offerItemPriceWithNDS',inputNDS,inputNoNDS,purchaseItem,initSumNDS,initSumNoNDS);
                        if( pairCheck==null && $scope.checkFieldPair(currentInput,inputNDS,inputNoNDS,purchaseItem,initSumNDS,initSumNoNDS)){
                            purchaseItem.offerItemPriceWithNDS_error = null;
                            purchaseItem.offerItemPriceNoNDS_error = null;
                            $scope.calcSums(purchaseItem);
                        } else {
                            if($scope.hasOfferErrors(true))
                                $scope.participate.part3.priceOfferWithNDS = null;
                            if($scope.hasOfferErrors(false))
                                $scope.participate.part3.priceOfferWithoutNDS = null;
                        }
                    }
                    else
                    {
                        if(currentInput==='offerItemPriceWithNDS') {
                            purchaseItem.offerItemPriceWithNDS_error = checkResult;
                            $scope.participate.part3.priceOfferWithNDS = null;
                        } else {
                            purchaseItem.offerItemPriceNoNDS_error = checkResult;
                            $scope.participate.part3.priceOfferWithoutNDS = null;
                        }

                    }

                };

                $scope.checkCurrentField = function(currentInput,inputNDS,inputNoNDS,purchaseItem,initSumNDS,initSumNoNDS){
                    var result = null;
                    if(currentInput==='offerItemPriceWithNDS') {
                        if (inputNDS==='' || isNaN(inputNDS) || inputNDS == null) {
                            purchaseItem.offerItemPriceWithNDS = null;
                            result = 'Поле не заполнено.'
                        } else if ((initSumNDS * 1 < inputNDS * 1) && $scope.participate.purchase.procPriceType === 'MAX_PRICE') {
                            result = 'Предложенная за единицу цена не должна превышать начальную цену за единицу.'
                        } else if ((initSumNDS * 1 > inputNDS * 1) && $scope.participate.purchase.procPriceType === 'MIN_PRICE') {
                            result = 'Предложенная за единицу цена не должна быть меньше начальной цены за единицу.'
                        } else {
                            result = null;
                        }
                    } else if(currentInput==='offerItemPriceNoNDS') {
                        if (inputNoNDS==='' || isNaN(inputNoNDS) || inputNoNDS == null) {
                            purchaseItem.offerItemPriceNoNDS = null;
                            result = 'Поле не заполнено.'
                        } else if ((initSumNoNDS * 1 < inputNoNDS * 1) && $scope.participate.purchase.procPriceType === 'MAX_PRICE') {
                            result = 'Предложенная за единицу цена не должна превышать начальную цену за единицу.'
                        } else if ((initSumNoNDS * 1 > inputNoNDS * 1) && $scope.participate.purchase.procPriceType === 'MIN_PRICE') {
                            result = 'Предложенная за единицу цена не должна быть меньше начальной цены за единицу.'
                        } else {
                            result = null;
                        }
                    }
                    $scope.calcFieldSum(currentInput, purchaseItem,result);

                    return result;
                };

                $scope.calcFieldSum = function(currentInput,purchaseItem,isOfferCorrect) {
                    if(currentInput==='offerItemPriceWithNDS'){
                        if (isOfferCorrect==null && (!purchaseItem.offerItemPriceWithNDS_error || purchaseItem.offerItemPriceWithNDS_error == null))
                            purchaseItem.sumItemPriceWithNDS = purchaseItem.qty * purchaseItem.offerItemPriceWithNDS;
                        else
                            purchaseItem.sumItemPriceWithNDS = null;
                    } else if(currentInput==='offerItemPriceNoNDS') {
                        if (isOfferCorrect==null && (!purchaseItem.offerItemPriceNoNDS_error || purchaseItem.offerItemPriceNoNDS_error == null))
                            purchaseItem.sumItemPriceNoNDS = purchaseItem.qty * purchaseItem.offerItemPriceNoNDS;
                        else
                            purchaseItem.sumItemPriceNoNDS = null;
                    }
                };

                // проверяем заполненность обоих полей. если оба заполнены - проверяем, что с НДС больше или равно без НДС
                $scope.checkFieldPair = function(currentInput,inputNDS,inputNoNDS,purchaseItem){
                    if(inputNoNDS * 1 > inputNDS * 1) {
                        purchaseItem.offerItemPriceWithNDS_error = 'Предложенная цена за единицу без НДС не должна превышать Предложенную цену за единицу с НДС.';
                        $scope.participate.part3.priceOfferWithNDS = null;
                        return false;
                    }
                    return true;
                };

                $scope.hasOfferErrors = function() {
                    var hasErrors = false;
                    angular.forEach($scope.participate.part3.purchaseItems.purchaseItem, function (purchaseItem) {
                        if (purchaseItem.offerItemPriceWithNDS_error!==null || purchaseItem.offerItemPriceNoNDS_error!==null)
                            hasErrors = true;
                    });
                    return hasErrors;
                };
                $scope.hasOfferErrors = function(isNDS) {
                    var hasErrors = false;
                    angular.forEach($scope.participate.part3.purchaseItems.purchaseItem, function (purchaseItem) {
                        if ((isNDS && purchaseItem.offerItemPriceWithNDS_error!==null) || (!isNDS && purchaseItem.offerItemPriceNoNDS_error!==null))
                            hasErrors = true;
                    });
                    return hasErrors;
                };
                $scope.calcSums = function(purchaseItem) {

                    if (!purchaseItem.offerItemPriceWithNDS_error || purchaseItem.offerItemPriceWithNDS_error == null)
                        purchaseItem.sumItemPriceWithNDS = Number((purchaseItem.qty * purchaseItem.offerItemPriceWithNDS).toFixed(2));
                    else
                        purchaseItem.sumItemPriceWithNDS = null;
                    if (!purchaseItem.offerItemPriceNoNDS_error || purchaseItem.offerItemPriceNoNDS_error == null)
                        purchaseItem.sumItemPriceNoNDS = Number((purchaseItem.qty * purchaseItem.offerItemPriceNoNDS).toFixed(2));
                    else
                        purchaseItem.sumItemPriceNoNDS = null;
                    
                    var mainSumNDS = 0, mainSumNDSCalc = true, mainSumNoNDS = 0, mainSumNoNDSCalc = true;

                    angular.forEach($scope.participate.part3.purchaseItems.purchaseItem, function (purchaseItem) {
                        if (purchaseItem.offerItemPriceWithNDS==null || isNaN(purchaseItem.offerItemPriceWithNDS))
                            mainSumNDSCalc = false;
                        else
                            mainSumNDS = mainSumNDS + purchaseItem.sumItemPriceWithNDS;
                        if (purchaseItem.offerItemPriceNoNDS==null || isNaN(purchaseItem.offerItemPriceNoNDS))
                            mainSumNoNDSCalc = false;
                        else
                            mainSumNoNDS = mainSumNoNDS + purchaseItem.sumItemPriceNoNDS;
                    });


                    if (mainSumNDSCalc) {
                        $scope.participate.part3.priceOfferWithNDS = Number(mainSumNDS).toFixed(2);
                        $scope.participate.part3.priceOfferWithNDS_error = null;
                    } else {
                        $scope.participate.part3.priceOfferWithNDS = null;
                    }

                    if (mainSumNoNDSCalc) {
                        $scope.participate.part3.priceOfferWithoutNDS = Number(mainSumNoNDS).toFixed(2);
                        $scope.participate.part3.priceOfferWithoutNDS_error = null;
                    } else {
                        $scope.participate.part3.priceOfferWithoutNDS = null;
                    }
                };
                $scope.isSimpleContest = function(){
                    return $scope.participate.purchase.typeCode==='simplecontest';
                };
                $scope.isNomenclaturePrice = function(){
                    return $scope.participate.lot.methodPriceSet==='method3';
                };
                $scope.isSimpleContest44 = function(){
                    return $scope.isSimpleContest() && $scope.participate.section==='44';
                };
                
                function closeAlertClaimBalanceFn() {
                	delete $scope.alertClaimBalance;
                }
                function isPercentModeFn(){
                    return $scope.participate && $scope.participate.purchase && $scope.participate.purchase.procPriceMode==='percent' &&
                        !['simplecontest','openauction','limitedauction','prequalification','unlimitedprequalification',
                            'offerplacement','limitedcontestqualification','openpublicoffer','saleauction'].includes($scope.participate.purchase.typeCode);
                }
                $scope.hasNoDivision = function (){
                    return !(Array.isArray($scope.data.availableOptions) && $scope.data.availableOptions.length>0);
                }

                $scope.getLotContractSumTitle = function(){
                    return `Начальная ${$scope.participate.purchase.procPriceType === "MAX_PRICE" ? "(максимальная)"
                        : $scope.participate.purchase.procPriceType === "MIN_PRICE" ? "(минимальная)" : ""} цена ${$scope.isLotPriceForItem()?
                        "за единицу товара" : "договора по лоту"}`;
                };

                $scope.isLotPriceForItem = function(){
                    const lotId = $scope.participate?.lot?.id;
                    return lotId ? ctrl.procedure.lots.find(lot => lot.id === lotId)?.priceForItem : false;
                };
                $scope.isApplicationSupplyNeeded = function(){
                    const lotId = $scope.participate?.lot?.id;
                    return lotId ? ctrl.procedure.lots.find(lot => lot.id === lotId)?.applicationSupplyNeeded : false;
                };

                $scope.showProvidedBankGuaranteeCheck = function(){
                    return $scope.isApplicationSupplyNeeded() && ['223', 'commercial'].includes(ctrl.procedure.section)
                        && ['MAX_PRICE','NO_PRICE'].includes($scope.participate.purchase.procPriceType)
                        && (!$scope.participate.rebiddingNumber || $scope.participate.rebiddingNumber===0)
                        && !(['limitedrequestproposals','requestproposals','open2stagecontest'].includes($scope.participate.purchase.typeCode) && $scope.participate?.stage === "stage2")
                        && !['simplecontest','prequalification','unlimitedprequalification','openpublicoffer','saleauction'].includes($scope.participate.purchase.typeCode);
                }

                $scope.changeProvidedBankGuarantee = function() {
                    if($scope.model.providedBankGuarantee) {
                        $scope.sendBlockFlag = false;
                        delete $scope.alertClaimBalance;
                    } else
                        $scope.providedBankGuaranteeCheck();
                }

                $scope.providedBankGuaranteeCheck = function(){
                    // После отключения чек-бокса проверка достаточности свободных средств должна выполниться повторно;
                    participateSrv.providedBankGuaranteeCheck($stateParams).then(function successCallback(response) {
                        if(response.data.success && response.data.result!=null && response.data.result.errorAvailableBalance){
                            $scope.sendBlockFlag = true;
                            $scope.alertClaimBalance = response.data.result.errorAvailableBalanceMessage;
                        } else
                            console.log(response);

                    }, function errorCallback(response) {
                        console.log(response);
                    });
                }
                $scope.getCurrencySign = (code) => {
                    return (' ' + $scope.currencyDict.find(c=>c.code==code)?.sign||'')
                }
                $scope.getStage = () => {
                    let res;
                    if($scope.participate?.purchase?.typeCode!=='unlimitedprequalification' && $scope.participate.rebiddingNumber>0) {
                        res = 'переторжка в ' + ($scope.participate.rebiddingType==='online' ? 'режиме реального времени №': 'заочной форме №') + $scope.participate.rebiddingNumberOnType;
                    } else {
                        res = $scope.getStageTitle();
                    }
                    return res ? (' (' + res + ')') : '';
                }
            }
        ]
    });
