/**
 * @author: sergeyu
 * Сервис для компоненты профиль организации
 */

angular.module('uetp').service('profileSrv', ['$state', 'httpSrv', 'desktopSrv', '$httpParamSerializerJQLike', 'authSrv',
    'dialogSrv', 'cryptography', '$injector', '$location', '$anchorScroll', 'organizatorSrv', 
    function ($state, httpSrv, desktopSrv, $httpParamSerializerJQLike, authSrv, dialogSrv, cryptography, $injector, $location,
              $anchorScroll, organizatorSrv) {
        let service = this,
            maxCountAttachments = 0;

        this.updateParticipantOrg = updateParticipantOrgFn;
        this.updateUserInfo = updateUserInfoFn;
        this.loadOrganizationInfo = loadOrganizationInfoFn;
        this.validateProfileModel = validateProfileModelFn;
        this.loadAgentsList = loadAgentsListFn;
        this.loadBgAgentsList = loadBgAgentsListFn;
        this.getAgentById = getAgentByIdFn;
        this.loadAccreditationList = loadAccreditationListFn;
        this.accelerationAccredAvailable = accelerationAccredAvailableFn;
        this.getAccreditationById = getAccreditationByIdFn;
        this.isOrgFirstAccred = isOrgFirstAccredFn;
        this.getAccreditationPrefillData = getAccreditationPrefillDataFn;
        this.getAccreditationByOrgId = getAccreditationByOrgIdFn;
        this.downloadDocsArchive = downloadDocsArchiveFn;
        this.loadPayersList = loadPayersListFn;
        this.loadAccountsList = loadAccountsListFn;
        this.getPersonalAccount = getPersonalAccountFn;
        this.revokeAccred = revokeAccredFn;
        this.loadFiliationList = loadFiliationListFn;
        this.loadFiliationsByParticipant = loadFiliationsByParticipantFn;
        this.loadFiliationListByClaim = loadFiliationListByClaimFn;
        this.getFiliationById = getFiliationByIdFn;
        this.deleteFiliation = deleteFiliationFn;
        this.storeFiliation = storeFiliationFn;
        this.updateAgents = updateAgentsFn;
        this.updateBgAgentsById = updateBgAgentsByIdFn;
        this.uploadCert = uploadCertFn;
        this.uploadCustomerCert = uploadCustomerCertFn;
        this.addCertificate = addCertificateFn;
        this.addCertificateToUser = addCertificateToUserFn;
        this.registerCertificate = registerCertificateFn;
        this.loadCertificatesByUserId = loadCertificatesByUserIdFn;
        this.loadCertificates = loadCertificatesFn;
        this.loadUserMchds = loadUserMchdsFn;
        this.loadAgentMchds = loadAgentMchdsFn;
        this.updateUserMchd = updateUserMchdFn;
        this.mchdLoginCertCheck = mchdLoginCertCheckFn;
        this.logUserMchd = logUserMchdFn;
        this.saveNewAgent = saveNewAgentFn;
        this.sendConfirmationEmail = sendConfirmationEmailFn;
        this.rejectConfirmationEmail = rejectConfirmationEmailFn;
        this.sendConfirmationOrgMail = sendConfirmationOrgMailFn;
        this.rejectConfirmationOrgMail = rejectConfirmationOrgMailFn;
        this.getAccountRefund = getAccountRefundFn;
        this.resetLoginPassword = resetLoginPasswordFn;
        this.downloadUserPowerOfAttorneyAgentByFileId = downloadUserPowerOfAttorneyAgentByFileIdFn;
        this.getAvailableOrganizers = getAvailableOrganizersFn;

        this.blockUser = blockUserFn;
        this.deleteAgent = deleteAgentFn;
        this.deleteAccredAttachment = deleteAccredAttachmentFn;

        this.checkAccred = checkAccredFn;
        this.checkAccredSecured = checkAccredSecuredFn;

        this.loadCashFlowInfo = loadCashFlowInfoFn;
        this.loadRevise = loadReviseFn;
        this.loadBlockedFoundsSumInfo = loadBlockedFoundsSumInfoFn;
        this.verifyCertSignature = verifyCertSignatureFn;
        this.checkBGLimits = checkBGLimitsFn;
        this.showDialogRefundOperation = showDialogRefundOperationFn;
        this.setMaxCountAttachments = function (userData) {
            maxCountAttachments = userData.maxCountAttachments ? userData.maxCountAttachments : 5;
        };
        this.getMaxCountAttachments = function () {
            return maxCountAttachments;
        };
        this.appendAllows = appendAllowsFn;
        this.getMchdStateDesc = getMchdStateDescFn;
        this.customersLoad = customersLoadFn;
        /**
         * Сброс формы блокировки
         */
        this.reasonReset = () => {
            return {
                "show": "",
                "agent": "",
                "id": "",
                "set": {}
            };
        }

        /**
         * Вернуться к отредактированному Агенту
         */
        this.scrollToAgent = (agentId) => {
            $location.hash(agentId);
            $anchorScroll();
            $location.hash(null);

            const element = document.getElementById(agentId).parentNode;
            element.classList.add('animate__animated', 'animate__headShake');
            element.addEventListener('animationend', () => {
                element.classList.remove('animate__animated', 'animate__headShake')
            });
        }

        this.setBlockStatus = (agent) => {
            this.reason = this.reasonReset();
            this.reason.show = true;
            this.reason.agent = agent;
            this.reason.id = agent.id;
            this.reason.set.result = !agent.blocked;
            this.reason.set.message = '';
            window.scrollTo(0, 0);

            return this.reason;
        }

        this.hideSetBlockStatus = (agentId) => {
            this.scrollToAgent(agentId);
            return this.reasonReset();
        }

        /**
         * Обновление реквизитов организации, которой принадлежит пользователь
         * @param org - реквизиты
         * @returns {*}
         */
        this.updateOrg = (org) => {
            if(!organizatorSrv.checkRequisites(org.requisites))
                delete org.requisites;

            const url = desktopSrv.getCabType() === 'admincab' || desktopSrv.getCabType() === 'accountant' ?
                `/admin/updateOperatorInfo` : `/auth/org/updateOrganizationInfo`;
            return httpSrv.http({
                url: url,
                data: org,
                block: true
            })
        }

        /**
         * Обновление реквизитов конкретной организации участника
         * @param org - реквизиты
         * @returns {*}
         */
        function updateParticipantOrgFn(org) {
            return httpSrv.http({
                url: '/auth/org/updateOrganizationInfo',
                data: org,
                block: true
            })
        }

        /**
         * Сброс логина и пароля
         *
         * @param user - пользователь
         * @returns {*}
         */
        function resetLoginPasswordFn(user) {
            if(user == undefined){
                return;
            }
            return httpSrv.http({
                method: 'GET',
                url: '/auth/registration/resetLoginPassword/' + user.id
            });
        }

        /**
         * Обновление реквизитов пользователя
         * @param org - реквизиты
         * @param clearMchd - с очисткой МЧД пользователя
         * @returns {*}
         */
        function updateUserInfoFn(org, clearMchd = false) {
            return httpSrv.http({
                url: `/auth/user/updateUserInfo/${clearMchd}`,
                data: org,
                block: true
            })
        }


        /**
         * Получение реквизитов организации
         * @returns {*}
         */
        async function loadOrganizationInfoFn() {
            let url = '', cabType = await new Promise(r=>desktopSrv.getCabType(r));
            if (["admincab", "accountant", "inspector", "support", "manager"].includes(cabType)) {
                url = '/admin/getOperatorInfo';
            } else if (["agent"].includes(cabType)) {
                url = '/admin/getBankGuaranteeAgentInfo';
            } else {
                url = '/auth/user/getOrganizationInfo';
            }
            return await httpSrv.http({
                method: 'GET',
                url: url
            })
        }

        /**
         * Получение списка пользователей пренадлежащих к организации
         * @param id
         * @returns {*}
         */
        function loadAgentsListFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/getAgentsList/' + id
            })

        }

        /**
         * Получение списка Агентов для банковских гарантий
         * @param id
         * @returns {*}
         */
        function loadBgAgentsListFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/getBgAgentsList/' + id
            })
        }

        /**
         * Проверка валидации сертификата
         * @param certForUpload
         * @returns {*}
         */
        function uploadCertFn(certForUpload) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/registration/validateCertificate',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: $httpParamSerializerJQLike(certForUpload)
            })
        }

        /**
         * Проверка валидации сертификата Заказчика
         * @param certForUpload
         * @returns {*}
         */
        function uploadCustomerCertFn(certForUpload) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/registration/validateCustomerCertificate',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: $httpParamSerializerJQLike(certForUpload)
            })
        }

        /**
         * Добавление сертификата
         */
        function addCertificateFn(user) {
            return httpSrv.http({
                url: '/ccwe/rest/registration/addCertificate',
                data: user
            })
        }

        /**
         * Добавление сертификата
         */
        function addCertificateToUserFn(data) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/user/addCertificateToUser',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: $httpParamSerializerJQLike(data)
            })
        }       

        /**
         * Добавление сертификата
         */
        function registerCertificateFn(certForUpload) {
            return httpSrv.http({
                url: '/auth/user/registerCertificate',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: $httpParamSerializerJQLike(certForUpload)
            })
        }

        /**
         * Получение сертификатов пренадлежащих пользователю
         * @param storage
         */
        function loadCertificatesFn(storage) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/getCertificates'
            }).then(function successCallback(response) {
                if (response.status === 200) {
                    storage.storedCerts = response.data.sort(actualCert);
                    if (!Array.isArray(storage.storedCerts)) return;
                    storage.storedCerts.forEach(function (el) {
                        var subjectName = el.subject,
                            fio = convertCertField(subjectName.match(/CN=[А-Яа-яёЁ\s]{1,}/g)),
                            io = convertCertField(subjectName.match(/GIVENNAME=[А-Яа-яёЁ\s]{1,}/g)),
                            lastName = convertCertField(subjectName.match(/SURNAME=[А-Яа-яёЁ]{1,}/g)),
                            inn = convertCertField(subjectName.match(/1.2.643.100.4=[0-9\s]{1,}/g) || subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g));
                        el.fio = io ? lastName + " " + io : fio;
                        el.inn = inn;
                    });
                }
                return Promise.resolve(response)
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Получение списка МЧД, пренадлежащих пользователю
         * @param storage
         */
        function loadUserMchdsFn(storage, userId) {
            return httpSrv.http({
                method: 'GET',
                url: `/auth/user/getUserMchds/${userId}`
            }).then(function successCallback(response) {
                if (response.status === 200) {
                    storage.storedUserMchds = response.data;
                }
                return Promise.resolve(response)
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Получение списка МЧД, пренадлежащих агенту
         * @param storage
         */
        function loadAgentMchdsFn(storage, agentId) {
            return httpSrv.http({
                method: 'GET',
                url: `/auth/user/getAgentMchds/${agentId}`
            }).then(function successCallback(response) {
                if (response.status === 200) {
                    storage.storedUserMchds = response.data;
                }
                return Promise.resolve(response)
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function updateUserMchdFn(storage, params) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/user/updateUserMchd',
                data: params
            }).then(function successCallback(response) {
                if (response.status === 200) {
                    storage.storedUserMchds = response.data.result || {};
                    // обновить user.mchd_id
                    if(storage.storedUserMchds.length>0) {
                        const user = authSrv.getUser();
                        user.mchdId = storage.storedUserMchds[0].mchdId;
                        authSrv.setUser(user);
                    }
                }
                return Promise.resolve(response)
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        function mchdLoginCertCheckFn(){
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/mchdLoginCertCheck',
                block: true,
            })
        }
        function logUserMchdFn() {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/logMchdVerificationFailed'
            })
        }

        /**
         * Получение сертификатов пренадлежащих пользователю по ID
         * @param storage
         */
        function loadCertificatesByUserIdFn(storage, userID) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/getCertificatesByUser/'+userID
            }).then(function successCallback(response) {
                if (response.status === 200) {
                    storage.storedCerts = response.data;
                    if (!Array.isArray(storage.storedCerts)) return;
                    storage.storedCerts.forEach(function (el) {
                        var subjectName = el.subject,
                            fio = convertCertField(subjectName.match(/CN=[А-Яа-яёЁ\s]{1,}/g)),
                            io = convertCertField(subjectName.match(/GIVENNAME=[А-Яа-яёЁ\s]{1,}/g)),
                            lastName = convertCertField(subjectName.match(/SURNAME=[А-Яа-яёЁ]{1,}/g)),
                            inn = convertCertField(subjectName.match(/1.2.643.100.4=[0-9\s]{1,}/g) || subjectName.match(/ИНН=[0-9\s]{1,}/g) || subjectName.match(/1.2.643.3.131.1.1=[0-9\s]{1,}/g));
                        el.fio = io ? lastName + " " + io : fio;
                        el.inn = inn;
                    });
                }
                return Promise.resolve(response)
            }, function errorCallback(response) {
                console.log(response);
            });
        }

        /**
         * Создание нового пользователя организации
         * @param agent
         * @returns {*}
         */
        function saveNewAgentFn(agent) {
            return httpSrv.http({
                url: '/auth/registration/registrationAgent',
                data: agent,
                block: true
            })
        }


        function loadBlockedFoundsSumInfoFn(paginator) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/account/browseOperationAccount',
                data: paginator
            })

        }

        function loadCashFlowInfoFn(paginator) {
            return httpSrv.http({
                url: '/auth/account/browseOperationAccount',
                data: paginator
            })
        }
        function loadReviseFn(paginator) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/account/browseOperationAccount',
                data: paginator
            })
        }

        function updateAgentsFn(agentsList) {
            return httpSrv.http({
                url: '/admin/updateAgents',
                data: agentsList
            })
        }
        function updateBgAgentsByIdFn(agentsList, id) {
            return httpSrv.http({
                url: '/auth/org/updateBgAgentsById/'+id,
                data: agentsList
            })
        }
        function getAgentByIdFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/getAgentById/'+ id
            })
        }

        function downloadUserPowerOfAttorneyAgentByFileIdFn(fileId) {
            var url = '/auth/org/getAgentPowerOfAttorney/' + fileId;
            return httpSrv.downloadFile(url);
        }

        /**
         * Проверка виладности реквизитов организации
         * @param profile
         */
        function validateProfileModelFn(profile) {
            var baseFieldValid = (profile.ogrn && profile.phone
                && (desktopSrv.getCabType() === 'customer' ? profile.code : true)
                && (profile.inn.length === 10 ? profile.kpp : true)
                && !!(profile.inn.length === 12 ? profile.lastName && profile.firstName : true));
            var realAddressValid = !!(profile.realAddress ? (profile.realAddress.zip && profile.realAddress.street
                && profile.realAddress.building && profile.realAddress.oksmCode) : true);
            var regAddressValid = !!(profile.regAddress ? (profile.regAddress.zip && profile.regAddress.street
                && profile.regAddress.building && profile.regAddress.oksmCode) : true);
            return baseFieldValid && realAddressValid && regAddressValid
        }

        function blockUserFn(reason) {
            return httpSrv.http({
                url: '/auth/user/block/' + reason.id,
                data: reason.set
            });
        }

        function deleteAgentFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/deleteAgent/'+ id
            })
        }
        function loadAccreditationListFn(paginator, id) {
            return httpSrv.http({
                method: 'POST',
                url: '/auth/org/browseAccreditations/'+id,
                data: paginator
            })
        }
        function accelerationAccredAvailableFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/accelerationAccredAvailable/'+id
            })
        }
        function getAccreditationByIdFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/getAccreditationById/'+ id
            })
        }

        function isOrgFirstAccredFn() {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/isOrgFirstAccred'
            })
        }

        function getAccreditationPrefillDataFn() {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/getAccreditationPrefillData'
            })
        }

        function getAccreditationByOrgIdFn() {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/getAccreditationByOrgId'
            })
        }
        function revokeAccredFn(data) {
            return httpSrv.http({
                method:'POST',
                url: '/auth/org/revokeAccreditation',
                data: data,
                block: true
            })
        }
        function deleteAccredAttachmentFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/deleteAccredAttachment/'+ id
            })
        }
        function loadFiliationListFn(paginator) {
            var paginationObj = paginator || {
                itemsPerPage: 100,
                page: 0
            };
            return httpSrv.http({
                method: 'POST',
                url: '/auth/org/browseFiliations',
                data: paginationObj
            })
        }
        function loadFiliationsByParticipantFn(id, paginator) {
            const paginationObj = paginator || {
                itemsPerPage: 100,
                page: 0
            };
            return httpSrv.http({
                method: 'POST',
                url: `/auth/org/browseFiliationsByParticipant/${id}`,
                data: paginationObj
            })
        }
        function loadFiliationListByClaimFn(claim, paginator ) {
            var paginationObj = paginator || {
                itemsPerPage: 100,
                page: 0
            };
            return httpSrv.http({
                method: 'POST',
                url: `/auth/org/browseFiliationsByClaim/${claim}`,
                data: paginationObj
            })
        }
        function getFiliationByIdFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/getFiliationById/'+ id
            })
        }
        function deleteFiliationFn(id) {
            return httpSrv.http({
                method:'GET',
                url: '/auth/org/deleteFiliation/'+ id
            })
        }
        function storeFiliationFn(data) {
            return httpSrv.http({
                url: '/auth/org/storeFiliation',
                data: data,
                block: true
            })
        }
        function sendConfirmationEmailFn(data){
            return httpSrv.http({
                url: '/auth/user/sendConfirmationEmail',
                data: data,
                block: true
            })
        }

        function rejectConfirmationEmailFn(){
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/rejectConfirmationEmail',
                block: true
            })
        }

        function sendConfirmationOrgMailFn(data){
            return httpSrv.http({
                url: '/auth/org/sendConfirmationEmail',
                data: data,
                block: true
            })
        }

        function rejectConfirmationOrgMailFn(){
            return httpSrv.http({
                method: 'GET',
                url: '/auth/org/rejectConfirmationEmail',
                block: true
            })
        }

        function checkAccredFn(procType){
            return httpSrv.http({
                method: 'POST',
                url: '/auth/user/accreditationCheck/true',
                block: true,
                data: {procType: procType}
            })
        }

        function checkAccredSecuredFn(procType, fullDateAccredCheck){
            return new Promise(function (resolve, reject) {
                if(desktopSrv.getCabType() === 'participant'){
                    httpSrv.http({
                        method: 'POST',
                        url: '/auth/user/accreditationCheck/' + (fullDateAccredCheck ? fullDateAccredCheck : 'false'),
                        block: true,
                        data: {procType: procType}
                    }).then(function (response) {
                        if (response) {
                            resolve(response)
                        }
                    }, function (reason) {
                        reject(reason)
                    })
                } else {
                    resolve({data:{result: true}})
                }
            })
        }



        function getAccountRefundFn(id){
            return httpSrv.http({
                method: 'GET',
                url: '/auth/account/getAccountRefund/' + id
            })
        }
        function downloadDocsArchiveFn(id){
            return httpSrv.downloadFile('/fs/file/downloadAttachmentArchive/' + id);
        }
        /**
         * Загрузить список платильщиков
         * */
        function loadPayersListFn(paginator,accountType){
            return httpSrv.http({
                method: 'POST',
                url: '/accounts/account/payersList/' + accountType,
                data: paginator
            })
        }

        function loadAccountsListFn(id){
            return httpSrv.http({
                method: 'GET',
                url: '/accounts/personalAccount/getPersonalAccountListByOrgId/' + id
            })
        }

        function getPersonalAccountFn(id) {
            return httpSrv.http({
                method: 'GET',
                url: '/accounts/personalAccount/getPersonalAccount/' + id
            })
        }
        function getAvailableOrganizersFn(){
            return httpSrv.http({
                method: 'GET',
                url: '/auth/user/listOrganization'
            })
        }
        function verifyCertSignatureFn(data,certId){
            return cryptography.generateSignature(window.Base64.encode(data), certId).then(function (value) {
                return httpSrv.http({
                    url: '/auth/user/verifySignature',
                    headers:{ 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: $httpParamSerializerJQLike(
                        {
                            signature: value,
                            text: data
                        }
                    )
                })
            }, function (reason) {
                return Promise.reject(reason)
            })

        }

        /**
         * Проверка банковской гарантии.
         * @param user - активный пользователь
         */
        function checkBGLimitsFn(user) {
            if (user) {
                var bgSrv = $injector.get('bankGuaranteeSrv');
                if (bgSrv) {
                    bgSrv.getLimitsCheckTypeOrg(user.typeOrg, user.inn, user.ogrn, user.fullName, user.id);
                }
            }
        }

        function showDialogRefundOperationFn($scope, operation) {
            if (operation.docId != '') {
                loadAppRefById(operation.docId).then(function () {
                    dialogSrv.showDialogWithTpl($scope, 'app/components/profile/personalAccount/applicationRefund.html');
                });
            }

            function loadAppRefById(id) {
                $scope.appRefView = true;
                $scope.appDoc = {};
                $scope.appDoc.organization = {};
                $scope.appDoc.account = {};
                $scope.appDoc.recipient = {};

                return service.getAccountRefund(id).then(function successCallback(response) {
                    if (response.data.success) {
                        $scope.appDoc = response.data.result;
                        if($scope.personalAccountData)
                            $scope.appDoc.account = $scope.personalAccountData.account; // номер лицевого счёта=номер счета со вкладки "Лицевой счет"
                        $scope.appDoc.recipient.kpp = $scope.appDoc.recipient.kpp === '0' ? "" : $scope.appDoc.recipient.kpp;
                        if ($scope.appDoc.recipient.bicBank) {
                            $scope.refreshDict('rBIK', $scope.appDoc.recipient.bicBank, false, true);
                        }
                    }
                }, function errorCallback(response) {
                    console.log(response);
                });
            }
        }

        /**
         * Добавление пользователю параметров прав
         * @param agent
         */
        function appendAllowsFn(agent){
            if(agent && agent.userProperties && ['organizer_user', 'organizer_admin', 'operator_admin'].includes(agent.userRole)){
                if(!agent.userProperties.allowed_223) agent.userProperties.allowed_223 = 'true';
                if(!agent.userProperties.allowed_44) agent.userProperties.allowed_44 = 'true';
                if(!agent.userProperties.allowed_commercial) agent.userProperties.allowed_commercial = 'true';
            }
        }

        function getMchdStateDescFn(code) {
            if(code==='valid')
                return 'Доверенность действует на момент подписания документа';
            else if(code==='expired')
                return 'Срок действия доверенности истек на момент подписания документа';
            else if(code==='revoked')
                return 'Доверенность отозвана на момент подписания документа';
            else if(code==='terminated')
                return 'Действие доверенности прекращено по событию ГК РФ на момент подписания документа';
            else if(code==='not valid')
                return 'Срок действия доверенности еще не наступил на момент подписания документа';
            else if(code==='undefined')
                return 'Ожидается результат проверки статуса доверенности от информационной системы хранения';
        }

        /**
         * Загрузка списка заказчиков организации
         */
        function customersLoadFn(paginator, orgId) {
            const page = paginator || {
                itemsPerPage: 20,
                page: 0,
            };

            return httpSrv.http({
                url: `/admin/customer/assignedCustomerListByOrganization/${orgId}`,
                data: page,
            });
        }
    }]);