/**
 * @author: sergeyu
 * Профиль пользователя. Добавление нового представителя
 */
angular.module('uetp')
    .component('participantAgentView', {
        templateUrl: 'app/components/participant/participantAgents/participantAgentView/participantAgentView.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'profileSrv', 'authSrv', 'desktopSrv', 'dictionarySrv', 'httpSrv', 'alertSrv', 'needConfirmSrv', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, profileSrv, authSrv, desktopSrv, dictionarySrv, httpSrv, alertSrv, needConfirmSrv, propertiesService) {
                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.userCertificates = {};
                    $scope.modelViewAgent = {isEdit: false};
                    $scope.saveAgent = saveAgentFn;
                    $scope.agent = {};
                    $scope.userMchds = {};
                    $scope.agentSaved = {};
                    $scope.cancel = cancelFn;
                    $scope.downloadFile = httpSrv.downloadFile;
                    $scope.resetLoginPassword = resetLoginPasswordFn;
                    $scope.onPersonalDataChanged = onPersonalDataChangedFn;
                    $scope.downloadPowerOfAttorney = downloadPowerOfAttorneyFn;
                    $scope.roleList = dictionarySrv.getStaticDictByType('roles')['participant'];
                    $scope.mchdInfo = 'В данном разделе возможно добавить машиночитаемую доверенность, выпущенную в едином формате, для подтверждения полномочий пользователя после добавления сертификата. ' +
                        '\nДля добавления выберите вместе xml-файл доверенности и файл(ы) открепленной подписи к этой доверенности.';
                    _initAgent();
                };
                $scope.goBack = goBackFn;

                function goBackFn() {
                    history.back();
                }

                function _initAgent() {
                    if ($stateParams.id) {
                        $scope.modelViewAgent.isEdit = true;
                        profileSrv.getAgentById($stateParams.id).then(function (response) {
                            if (response.data.success) {
                                $scope.agent = response.data.result;
                                $scope.agentSaved = angular.copy($scope.agent);
                                if (['admincab','support','accountant','inspector'].includes($scope.cabType())) {
                                    profileSrv.loadCertificatesByUserId($scope.userCertificates, $stateParams.id);
                                }

                                if(['organizer_admin', 'organizer_user', 'participant_admin','user'].includes($scope.agent.userRole)) {
                                    propertiesService.getProperty("mchd.url").then((host) => {
                                        if (!host.endsWith('/')) host += '/';
                                        $scope.mchdUrl = host;
                                        profileSrv.loadAgentMchds($scope.userMchds, $scope.agent.id);
                                    });
                                }
                            }
                        })
                    }
                }

                function saveAgentFn() {
                    $scope.agentList = [];
                    $scope.agentList[0] = {};
                    $scope.agentList[0].id = $scope.agent.id;
                    $scope.agentList[0].userRole = $scope.agent.userRole;
                    $scope.agentList[0].email = $scope.agent.email;
                    $scope.agentList[0].consentProcessingDate = $scope.agent.consentProcessingDate;
                    $scope.agentList[0].consentDisseminationDate = $scope.agent.consentDisseminationDate;
                    if($scope.cabType() === 'admincab') {
                        $scope.agentList[0].mchdReq = $scope.agent.mchdReq;
                    }

                    profileSrv.updateAgents($scope.agentList).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response,'Сохранение произведено успешно!');
                            $scope.agentSaved = angular.copy($scope.agent);
                        }
                    }, function errorCallback(response) {
                        $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response);
                        console.log(response);
                    });
                }

                function resetLoginPasswordFn(user) {
                    if(user == undefined){
                        return;
                    }
                    profileSrv.resetLoginPassword(user).then(function successCallback(response) {
                        if (response.data.success) {
                            $scope.alertObjOrgAgentEditor = alertSrv.getAlertObj(response, response.data.message);
                            _initAgent();
                        }
                    });
                }

                function cancelFn() {
                    if($scope.form.participantAgentViewForm.$dirty){
                        needConfirmSrv.call($scope.goBack,[],'Внесенные изменения сохранены не будут');
                    } else {
                        $scope.goBack();
                    }
                }


                function onPersonalDataChangedFn(){
                    $scope.agent.consentProcessingDate = null;
                    $scope.agent.consentDisseminationDate = null;
                }

                function downloadPowerOfAttorneyFn() {
                    profileSrv.downloadUserPowerOfAttorneyAgentByFileId($scope.agent.powerOfAttorney.id);
                }

                // МЧД
                $scope.hasActiveCert = () => {
                    return $scope.agent.certId ? $scope.userCertificates?.storedCerts?.find(cert=>cert.id===$scope.agent.certId) : false;
                }
                $scope.isMchdDisabled = () => !$scope.agent.mchdReq || !$scope.hasActiveCert()

                $scope.mchdCheckbox = () => {
                    if($scope.cabType() === 'admincab') {
                        $scope.agent.mchdReq = !$scope.agent.mchdReq;
                        $scope.form.participantAgentViewForm.$dirty = true;
                    }
                }
                $scope.getMchdLink = (mchd) =>
                    $scope.mchdUrl + 'full?poanum=' + mchd.mchdId + '&principal=' + $scope.agent.inn + '&agent=' + mchd.agentInn;
                }]
    });

