angular.module('uetp')
    .component('fasListArchive', {
        templateUrl: 'app/components/fas/fasListArchive/fasListArchive.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'constants', 'authSrv', 'desktopSrv', 'dialogSrv', 'profileSrv', 'cryptography', 'httpSrv', 'timeout', 'alertSrv',
            function ($scope, $rootScope, $state, $stateParams, constants, authSrv, desktopSrv, dialogSrv, profileSrv, cryptography, httpSrv, timeout, alertSrv) {
                $scope.constants = constants;
                $scope.downloadFasListArchive = downloadFasListArchiveFn;
                $scope.downloadFile = downloadFileFn;
                $scope.importLots = importLotsFn;
                $scope.cleanCheckBoxes = cleanCheckBoxesFn;

                this.$onInit = function () {
                    $scope.model = {};
                    $scope.modelView = {};
                    $scope.modelView.alertLotListImport = {};
                    $scope.goBack = goBackFn;
                };

                function importLotsFn(data) {
                        $scope.model.lots = data.result;
                        if(data.success) {
                            $scope.modelView.alertLotListImport = alertSrv.getSuccessMsg(data.message);
                        }                            
                }

                function cleanCheckBoxesFn() {
                    if (!$scope.model.withClaims) {
                        $scope.model.withContract = false;
                        $scope.model.withAccreditation = false;
                    }
                }

                $scope.$on('setShowThrobber', function (event, data) {
                    $scope.showThrobber = data;
                    if(!$scope.showThrobber) {
                        $scope.throbbers = [];
                    }
                    timeout($scope);
                });

                function downloadFileFn(archive) {
                    let lots = '';
                    if (archive.lots) {
                        archive.lots.forEach(item => lots += lots === '' ? item: (',' + item));
                    }

                    if (archive.extralarge) {
                        httpSrv.downloadFile(`/fs/file/procedureDocArchiveByLotId/${archive.procList[0]}/${lots}/${!!archive.withClaims}/${!!archive.withContract}/${!!archive.withAccreditation}/${encodeURIComponent(archive.fileName)}`);
                    } else {
                        $scope.throbbers.push(archive.fileName);
                        $rootScope.$broadcast('setShowThrobber', true);

                        let procList = '';
                        if (archive.procList) {
                            archive.procList.forEach(item => procList += procList === '' ? item: (',' + item));
                        }

                        const params = {
                            searchByField: {
                                procList: procList,
                                lot: lots,
                                withClaims: archive.withClaims,
                                fileName: archive.fileName,
                                withContract: archive.withContract,
                                withAccreditation: archive.withAccreditation
                            }
                        };

                        httpSrv.downloadFile('/fs/file/procedureLotList', archive.fileName, params);
                    }
                }

                function downloadFasListArchiveFn() {
                    if($scope.showThrobber) {
                        return;
                    }
                    $scope.searchProcess = true;
                    delete $scope.archives;
                    let lots = '';
                    if ($scope.model.lots) {
                        $scope.model.lots.forEach(item => lots += lots === '' ? item: (',' + item));
                    }

                    const pager = {
                        searchByField: {
                            lot: lots,
                            withClaims: $scope.model.withClaims,
                            withContract: $scope.model.withContract,
                            withAccreditation: $scope.model.withAccreditation
                        }
                    }

                    httpSrv.http({
                        method: 'POST',
                        url: `/admin/getFASList`,
                        data: pager
                    }).then((response)=>{
                        if(response.data.success) {
                            $scope.archives = response.data.result;
                            $scope.throbbers = [];
                        }
                        $scope.searchProcess = false;
                    });
                }

                $scope.onSizeLotError = function(files, maxfilesize){
                    angular.forEach(files, function (file) {
                        let errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                            'Превышен максимально разрешенный размер файла ('
                            + (maxfilesize /1048576 ).toFixed(2) + ' Мб)!<br />' +
                            'Размер файла ' + file.name + ': ' + (file.size / 1048576).toFixed(2) + ' Мб.';
                        $scope.modelView.alertLotListImport = alertSrv.getErrorMsg(errorMsg)
                    });
                };
                $scope.onSizeEmptyLotError = function(files){
                    angular.forEach(files, function (file) {
                        if (file.size === 0) {
                            let errorMsg = 'Файл ' + file.name + ' не добавлен.<br />' +
                                'Невозможно добавить пустой файл.';
                            $scope.modelView.alertLotListImport = alertSrv.getErrorMsg(errorMsg)
                        }
                    });
                };
                $scope.onFileNameLotError = function(files){
                    angular.forEach(files, function (file) {
                        let errorMsg = 'Импорт из файла  ' + file.name + ' невозможен.<br />' +
                            'Для импорта используйте файлы формата Microsoft Excel (xls или xlsx).';
                        $scope.modelView.alertLotListImport = alertSrv.getErrorMsg(errorMsg)
                    });
                };

                $scope.preCondition = function (callback, changeEvent) {
                    $scope.files = [];

                    angular.forEach(changeEvent.target.files, function(file) {
                        $scope.files.push(file);
                    });

                    callback($scope.files);
                };

                function goBackFn() {
                    history.back();
                }
            }]
    });

