/**
 * @author: sergeyu
 * Компонент формы регистрации
 */
var OKSM_RUS = '643';

angular.module('uetp')
    .component('registration', {
        templateUrl: 'app/components/registration/registration.html',
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'constants', 'authSrv', 'desktopSrv', 'dialogSrv', 'profileSrv', 'cryptography', 'dictionarySrv', 'httpSrv', 'alertSrv', 'timeout', '$filter', 'propertiesService',
            function ($scope, $rootScope, $state, $stateParams, constants, authSrv, desktopSrv, dialogSrv, profileSrv, cryptography, dictionarySrv, httpSrv, alertSrv, timeout, $filter, propertiesService) {
                this.$onInit = function () {
                    $scope.dictSrv = dictionarySrv;
                    $scope.mspCategory = dictionarySrv.getStaticDictByType('mspCategory');
                    $scope.processing = false;
                    $scope.middleNameCert = "";
                    $scope.msgError = '';
                    _initDict(['okcm', 'fias_region'], false);
                    if ($stateParams.id) {

                    }
                };
                $scope.reset = resetFn;
                $scope.fillSelect = fillSelectFn;
                $scope.checkedAddress = checkedAddressFn;
                $scope.register = registerFn;

                $scope.checkSubjectMSP = checkSubjectMSPFn;
                $scope.constants = constants;
                $scope.changeOksm = changeOksmFn;
                $scope.changeOkato = changeOkatoFn;
                $scope.uploadCert = uploadCertFn;
                $scope.changeNrType = changeNrTypeFn;
                $scope.refreshDict = refreshDictFn;

                //начальная инициализация
                init();

                function _initDict(dictList, reload) {
                    angular.forEach(dictList, function (name) {
                        dictionarySrv.getDictionaryRemote(name, reload)
                    });
                }

                function changeNrTypeFn(){
                    if ($scope.user.nrType === 'P'){
                        $scope.user.fullName = $scope.user.lastName + ' ' + $scope.user.firstName + ' ' + $scope.user.middleName;
                    } else{
                        $scope.user.fullName = $scope.oldFullName;
                    }

                }

                function changeOksmFn(object, select) {
                    $scope.user[object].regionOKATOCode = '';
                    $scope.user[object].regionOKATOName = '';
                    $scope.user[object].oksmName = select.$select.selected ? select.$select.selected.ncm : "";
                    if (select.$select.selected.kokcm3 === OKSM_RUS) $scope.user.regAddress.zip = '';
                }

                function changeOkatoFn(object, select) {
                    $scope.user[object].regionOKATOName = select.$select.selected ? (select.$select.selected.formalname + " " + select.$select.selected.shortname) : "";
                }

                /**
                 * Сброс регистрационных данных
                 */
                function resetFn() {
                    $scope.active = {};
                    $scope.cert = {};
                    $scope.profile = {};
                    $scope.user = {regAddress: {oksmCode: OKSM_RUS, oksmName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'}, realAddress: {}};
                    $scope.certspin = {};
                }

                /**
                 * Раскрытие селекта для выбора типа МСП
                 */
                function checkSubjectMSPFn(value) {
                    if(value === true) delete $scope.user.mspType;
                    $scope.subjectMsp = value;
                }
                /**
                 * Выбор способа регистрации
                 */
                $scope.changeRegType = (value) => {
                    if($scope.processing) return;
                    $scope.alertObjRegistration = '';
                    $scope.regType = value;
                    delete $scope.files;
                    $scope.clearFormFields();
                    $scope.active.show = false;
                    if($scope.regType==='fl') {
                        if ($scope.selectedFlInn && !/^[0]+$/g.test($scope.user.inn)) {
                            httpSrv.http({
                                url: '/auth/registration/validateInn',
                                data: {param: $scope.selectedFlInn, message: $scope.typeOrg},
                                block: true
                            }).then(function successCallback(response) {
                                if (!response.data.success) {
                                    $scope.alertObjRegistration = alertSrv.getErrorMsg(response.data.message);
                                    $scope.showPrincipals = false;
                                } else {
                                    if ($scope.regType === 'fl' && $scope.validatedCert) {
                                        fillFields($scope.validatedCert);
                                        $scope.showPrincipals = false;
                                    }
                                    $scope.active.show = true;
                                }
                            })
                        } else {
                            if (!$scope.selectedFlInn || $scope.regType === 'fl')
                                $scope.active.show = true;
                        }
                    }
                    if($scope.regType==='mchd')
                        $scope.showFileSelectBtn = true;
                }
                $scope.deleteAttach = (index) => {
                    if($scope.files)
                        delete $scope.files[index];
                    $scope.showFileSelectBtn = $scope.files.length===0;
                }
                $scope.mchdRegImport = (response) => {
                    if(response.success) {
                        if (response.result?.attorney)
                            $scope.attorney = response.result?.attorney;
                        else
                            delete $scope.attorney;
                        if (response.result?.attorney?.countPrincipals === 1) {
                            $scope.changePrincipal(response.result?.attorney?.principals[0]);
                            $scope.showFileSelectBtn = false;
                        } else if (response.result?.attorney?.countPrincipals > 1) {
                            $scope.showPrincipals = true;
                            $scope.showFileSelectBtn = false;
                            $scope.principals = [];
                            response.result?.attorney?.principals.forEach(p => {
                                $scope.principals.push(p);
                            })
                        } else {
                            $scope.alertObjRegMchd = alertSrv.getErrorMsg(response.result.message);
                            if (response.result.code === '400')
                                $scope.showFileSelectBtn = true;
                        }
                    } else if(!response.success && response.errorMessage){
                        $scope.alertObjRegMchd = alertSrv.getErrorMsg((response.errorMessage||'').replaceAll('\n','<br/>'));
                    }
                }
                $scope.changePrincipal = (selected) => {
                    $scope.processing = true;
                    if(selected.type===3) {
                        $scope.alertObjRegMchd = alertSrv.getErrorMsg('По вопросу регистрации с использованием МЧД физического лица просим обратиться в службу поддержки.');
                        $scope.processing = false;
                    } else {
                        let data = {param: selected.type===4 ? selected.regNum : selected.inn, message: getPrincipalType(selected.type)};
                        // ul
                        if(selected.inn?.length===10) {
                            // UETP-11026 Убрана проверка инн+кпп для ЮЛ из-за филиалов
                            data.id = selected.kpp;
                            $scope.processing = false;
                            $scope.active.show = true;
                            $scope.initRegForm(selected);
                            $scope.checkRegisteredUl();
                            return;
                        }
                        httpSrv.http({
                            url: `/auth/registration/validateInn${selected.inn?.length === 10 ? 'Kpp' : ''}`,
                            data: data,
                            block: true
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                $scope.active.show = true;
                                if(response.data.errorCode==='133')
                                    $scope.alertObjRegMchd = alertSrv.getErrorMsg("Организация с ИНН " + selected.inn + " из доверенности уже зарегистрирована.");
                                else if(response.data.errorCode==='133_2')
                                    $scope.alertObjRegMchd = alertSrv.getErrorMsg("Организация с ИНН " + selected.inn + " и КПП " + selected.kpp + " из доверенности уже зарегистрирована.");
                                $scope.initRegForm(selected);

                            } else {
                                $scope.active.show = false;
                                if (response.data.errorCode==='133') {
                                    $scope.alertObjRegMchd = alertSrv.getErrorMsg("Организация с ИНН " + selected.inn + " из доверенности уже зарегистрирована.");
                                } else if (response.data.errorCode==='133_2') {
                                    $scope.alertObjRegMchd = alertSrv.getErrorMsg("Организация с ИНН " + selected.inn + " и КПП " + selected.kpp + " из доверенности уже зарегистрирована.");
                                } else if (response.data.message) {
                                    $scope.alertObjRegMchd = alertSrv.getErrorMsg(response.data.message);
                                }
                            }
                            $scope.processing = false;
                        })
                    }
                }
                function getPrincipalType(type) {
                    return type===1 ? 'ul' : type===2 ? 'ip' : type===3 ? 'fl' : 'nr';
                }
                $scope.preCondition = function (callback, changeEvent) {
                    if(!$scope.files) {
                        $scope.files = [];
                    }
                    angular.forEach(changeEvent.target.files, function(file) {
                        $scope.files.push(file);
                    });

                    callback($scope.files);
                };
                $scope.deleteAttach = (index) => {
                    $scope.files.splice(index, 1);
                    if($scope.files.length===0){
                        $scope.files = [];
                    }
                    $scope.showFileSelectBtn = true;
                    $scope.showPrincipals = false;
                    $scope.active.show = false;
                }

                $scope.clearFormFields = () => {
                    ['okved','okvedOther','phone','phone','email','urlShop','snils','realAddress','nrType','orgMail',
                        'mspType','okpo','kpp'].forEach(f=> delete $scope.user[f]);
                    ['oksmCode','oksmName','zip','regionOKATOCode','regionOKATOName','area','settlement', 'settlement','street','building',
                     'subBuilding','office','office'].forEach(f=>delete $scope.user.regAddress[f]);
                    $scope.user.regAddress={'oksmCode':OKSM_RUS, 'oksmName': 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                }

                $scope.initRegForm = (principal) => {
                    if(principal.type===1) {
                        $scope.user.typeOrg = 'ul';
                        $scope.user.fullName = principal.name;
                        $scope.user.inn = principal.inn;
                        $scope.user.kpp = principal.kpp;
                        $scope.user.ogrn = principal.ogrn;
                    } else if(principal.type===2) {
                        $scope.user.typeOrg = 'ip';
                        $scope.user.lastNameHolder = principal.lastName;
                        $scope.user.firstNameHolder = principal.firstName;
                        $scope.user.middleNameHolder = principal.middleName;
                        $scope.user.inn = principal.inn;
                        $scope.user.ogrn = principal.ogrn;
                    } else if(principal.type===3) {
                        // пока не регистрируется
                        $scope.user.typeOrg = 'fl';
                    } else if(principal.type===4) {
                        $scope.user.typeOrg = 'nr';
                        $scope.user.nrType = 'L';
                        $scope.user.fullName = principal.name;
                        $scope.user.uin = principal.regNum;
                    }
                    if ($scope.user.typeOrg !== 'nr') {
                        delete $scope.user.nrType;
                    }

                    if([1, 2, 4].includes(principal.type)) {
                        cryptography.parseSubjectName($scope.selectedCert.subjectName).then(function successCallback(parsedSubject){
                            $scope.user.lastName = parsedSubject.lastName;
                            $scope.user.firstName = parsedSubject.firstName;
                            $scope.user.middleName = parsedSubject.middleName;
                            if(parsedSubject.email)
                                $scope.user.email = parsedSubject.email;
                        });
                    }
                };

                /**
                 * Инициализация селекта сертификатов которые находятся в личном хранилище
                 */
                function fillSelectFn() {
                    if(!authSrv.getAuth().withoutCertEnabled) {
                        resetFn();
                        $scope.active.cert = 'yes';
                        cryptography.ready.then(function () {
                            const selectCert = document.getElementById("cert");
                            if (selectCert) {
                                cryptography.initPluginWorking().then(function (plugin) {
                                    plugin.initHtmlChecker();
                                    fillAllCertByStore(plugin, 'cert');
                                });

                                selectCert.addEventListener('change', certChangeHandler);
                                bindFormEnterPress();
                            }
                        }, function(error){
                            return cryptography.errorHandler(error).catch(function () {});
                        });
                    }
                }

                fillSelectFn();

                /**
                 * Совпадает ли адрес с юридическим
                 * @param checked
                 */
                function checkedAddressFn(checked) {
                    if (checked) {
                        $scope.prevRealAddress = angular.copy($scope.user.realAddress);
                        delete $scope.user.realAddress;
                    } else {
                        $scope.user.realAddress = angular.copy($scope.prevRealAddress);
                    }
                    $scope.isChecked = !$scope.isChecked;
                    $scope.form.registerForm.$setDirty();
                }

                /**
                 * Регистрация организации на серверер
                 */
                function registerFn(timelimit = false) {
                    if(!$scope.form.registerForm.$valid || ($scope.selectedFlInn && !$scope.regType)) {
                        return;
                    }

                    //ie 11 баг снятие фокуса
                    window.focus();
                    if ($scope.processing) {
                        return;
                    }
                    $scope.processing = true;

                    if('ul' === $scope.user.typeOrg) {
                        httpSrv.http({
                            url: '/auth/registration/validateInn',
                            data: {param: $scope.user?.inn, message: $scope.user.typeOrg},
                            block: true
                        }).then((response) => {
                            if (!response.data.success) {

                                dialogSrv.openModal('_confirmInfoExt.html', {
                                    title: "Внимание!",
                                    message: `На Комита ЭТП уже зарегистрирована организация с ИНН ${$scope.user?.inn}, но с другим КПП.\n` +
                                            `Вы действительно хотите продолжить регистрацию организации с КПП ${$scope.user?.kpp}?\n\n` +
                                            `Произвести смену КПП, а также получить более подробную информацию можно в <a href="/contacts" target="_blank">Службе поддержки</a>.`,
                                    buttons: [{
                                        title: "Продолжить регистрацию",
                                        btnClass: "btn-success",
                                        closeValue: false,
                                        action: () => {
                                            sendReg();
                                        }
                                    },{
                                        title: "Отмена",
                                        btnClass: "btn-danger",
                                        closeValue: true,
                                        action: () => {
                                            $scope.processing = false;
                                        }
                                    }]
                                });
                            } else {
                                setTimeout(() => {
                                    sendReg();
                                }, 1000)
                            }
                        });
                    } else {
                        setTimeout(() => {
                            sendReg();
                        }, 1000);
                    }

                    function sendReg() {
                        $scope.submitted = true;
                        $scope.userTemp = angular.copy($scope.user);
                        if (!$scope.form.registerForm.$valid) {
                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Необходимо заполнить обязательные поля на форме.');

                            $scope.processing = false;
                            timeout($scope);
                            return;
                        }
                        if ($scope.showOrgPerson()) {
                            if ($scope.user.middleName === undefined || $scope.user.middleName === "undefined") {
                                $scope.user.middleName = '';
                            }
                            if($scope.user.typeOrg === 'ip' && $scope.selectedFlInn && $scope.regType==='mchd' && !$scope.user.fullName) {
                                $scope.user.fullName = $scope.user.lastNameHolder + ' ' + $scope.user.firstNameHolder + ($scope.user.middleNameHolder ? (' ' + $scope.user.middleNameHolder): '');
                            }
                        } else {
                            if ($scope.user.middleNameHolder === undefined || $scope.user.middleNameHolder === "undefined") {
                                $scope.user.middleNameHolder = '';
                            }
                            $scope.user.fullName = $scope.user.lastNameHolder + ' ' + $scope.user.firstNameHolder + ' ' + $scope.user.middleNameHolder;
                            $scope.user.lastName = angular.copy($scope.user.lastNameHolder);
                            $scope.user.firstName = angular.copy($scope.user.firstNameHolder);
                            $scope.user.middleName = angular.copy($scope.user.middleNameHolder);
                        }
                        if ($scope.isChecked) {
                            delete $scope.user.realAddress;
                        }

                        // Добавляем данные по МЧД
                        if(['ul','ip','nr'].includes($scope.user.typeOrg) && $scope.selectedFlInn && $scope.regType==='mchd' && $scope.attorney) {
                            $scope.user.mchdId = $scope.attorney.id;
                            $scope.user.issued = $scope.attorney.issued;
                            $scope.user.expired = $scope.attorney.expired;
                            $scope.user.state = $scope.attorney.state.code;
                            $scope.user.agentInn = $scope.selectedFlInn;
                        }

                        const consentProcessingEl = document.getElementById("consentProcessing");
                        const consentDisseminationEl = document.getElementById("consentDissemination");
                        $scope.user.consentProcessing = $filter('stripHTML')(consentProcessingEl.innerText);
                        $scope.user.consentDissemination = $filter('stripHTML')(consentDisseminationEl.innerText);

                        httpSrv.http({
                            url: '/auth/registration/generateXmlForSign',
                            data: $scope.user,
                            block: true
                        }).then(function successCallback(response) {
                            if (response.data.success) {
                                return cryptography.generateSignature(response.data.message, $scope.cert.thumbPrint).then(function (value) {
                                    $scope.user.createDate = response.data.result;
                                    $scope.user.signature = document.getElementById("signature").value = value;
                                    return httpSrv.http({
                                        url: '/auth/registration/registrationOrg',
                                        data: $scope.user,
                                        block: true
                                    }).then(function successCallback(response) {
                                        if (response.data.success) {
                                            dialogSrv.showDialogMsgHtml($scope, "На электронный адрес " + $scope.user.email + " отправлено письмо. " +
                                                (timelimit ? " Для завершения регистрации перейдите по указанной в письме ссылке в течение следующих 24 часов." :
                                                    " Для завершения регистрации новый представитель должен перейти по указанной в письме ссылке."), function () {
                                                $state.go('home');
                                            });
                                            $scope.reset();
                                        } else {
                                            $scope.user.lastNameHolder = $scope.userTemp.lastNameHolder;
                                            $scope.user.firstNameHolder = $scope.userTemp.firstNameHolder;
                                            $scope.user.middleNameHolder = $scope.userTemp.middleNameHolder;
                                            $scope.user.kpp = $scope.userTemp.kpp;
                                            dialogSrv.showDialogMsg($scope, response.data.message);
                                            $scope.alertObjRegistrationSave = alertSrv.getErrorMsg(response.data.message || response.data.errorMessage);
                                        }
                                        $scope.processing = false;
                                    });

                                }, function errorCallback(response) {
                                    if (response.indexOf('0x800B010A') >= 0) {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Нет доверия к этому корневому сертификату центра сертификации, поскольку он не найден в хранилище доверенных корневых сертификатов центров сертификации.');
                                    } else {
                                        $scope.alertObjRegistrationSave = alertSrv.getErrorMsg('Ошибка подписи данных для регистрации. Проверьте правильность установки сертифика на вашем устройстве.');
                                    }
                                    $scope.processing = false;
                                    console.log(response)
                                });
                            }
                        }).then(null, function errorCallback(response) {
                            $scope.alertObjRegistrationSave = alertSrv.getAlertObj(response);
                            $scope.processing = false;
                            console.log(response);
                        });
                    }
                }

                /**
                 * Выбор сертификата (весит на event)
                 */
                function certChangeHandler() {
                    $scope.active.show = false;
                    if ($scope.alertObjRegistration) $scope.alertObjRegistration.dismiss = true;

                    $scope.user = {};
                    $scope.oldFullName = '';
                    $scope.certspin = 'spin';
                    $scope.selectedCert = null;
                    if($scope.showPrincipals) {
                        $scope.showPrincipals = false;
                        delete $scope.principal;
                    }
                    delete $scope.selectedFlInn;
                    delete $scope.regType;
                    delete $scope.files;
                    $scope.showFileSelectBtn = true;

                    if (!this.value) return $scope.$digest();
                    var certObj = this[this.value],
                        subjectName = certObj.subjectName;
                    $scope.selectedCert = certObj;
                    if (!subjectName) return;

                    const certValidate = {"certInBase64": certObj.base64Value};

                    profileSrv.uploadCert(certValidate).then((response) => {
                        if (response.data.success) {
                            if (response.data?.result?.typeOrg === 'fl') {
                                $scope.selectedFlInn = response.data?.result?.inn;
                            } else {
                                delete $scope.selectedFlInn;
                            }

                            $scope.cert = certObj;
                            $scope.user.certId = response.data.message;
                            fillFields(response.data.result);
                            $scope.validatedCert = response.data.result;
                            $scope.user.regAddress = {oksmCode: OKSM_RUS, oksmName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                            if (!$scope.user.ogrn) {
                                $scope.user.ogrn = '0000000000000'
                            }
                        } else {
                            $scope.user = {
                                regAddress: {oksmCode: OKSM_RUS, oksmName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'},
                                realAddress: {}
                            };
                            $scope.alertObjRegistration = alertSrv.getErrorMsg(response.data.message);
                        }
                    }, (response) => {
                        console.log(response);
                    });
                }
                /**
                 * Разбор данных сертификата
                 * @param regJson
                 */
                function fillFields(regJson) {
                    var innVal     = regJson.inn,
                        ogrn       = regJson.ogrn,
                        email      = regJson.email,
                        firstName  = regJson.firstName,
                        middleName = regJson.middleName,
                        lastName   = regJson.lastName;

                    $scope.user.typeOrg = regJson.typeOrg;
                    $scope.user.uin     = regJson.uin;

                    // если только нули, значит нерезидент
                    if ($scope.user.typeOrg === 'nr'/*/^[0]+$/g.test(innVal) || innVal.trim() === ''*/) {
                        // if (angular.isDefined(ogrn) && (/^[0]+$/g.test(ogrn) || ogrn.trim() === '')) {
                        //     $scope.user.nrType = 'L'; //Нерезидент ЮЛ
                        // } else {
                        //     $scope.user.nrType = 'P'; //Нерезидент ФЛ
                        // }
                        //по умолчанию Нерезидент - ЮЛ UETP-1599
                        $scope.user.nrType = 'L';
                        //$scope.user.typeOrg = 'nr'; //$scope.active.type = 4;
                    } /*else if (innVal.length === 10) {
                        $scope.user.typeOrg = 'ul'; //$scope.active.type = 1;
                    } else if (innVal.length === 12) {

                        if (ogrn) {
                            $scope.user.typeOrg = 'ip'; //$scope.active.type = 2;
                        } else {
                            $scope.user.typeOrg = 'fl'; //$scope.active.type = 3;
                        }
                    }*/

                    $scope.profile = {legalEntity: {fio: {}}, person: {fio: {}}};
                    if ($scope.user.typeOrg === 'ul' || $scope.user.typeOrg === 'nr' && $scope.user.nrType === 'L') {
                        $scope.user.fullName = regJson.fullName;
                        $scope.oldFullName = regJson.fullName;
                    }

                    if (innVal.length === 10 && (ogrn && ogrn.length !== 13 )) {
                        dialogSrv.showDialogMsg($scope, "ОГРН (ОГРНИП) в сертификате не соответствует типу организации (ЮЛ, ИП или ФЛ). Возможно, сертификат выпущен неверно.");
                        return;
                    }
                    //ип
                    if (innVal.length === 12 && $scope.user.typeOrg === 'ip' && (ogrn && ogrn.length !== 15)) {
                        dialogSrv.showDialogMsg($scope, "ОГРН (ОГРНИП) в сертификате не соответствует типу организации (ЮЛ, ИП или ФЛ). Возможно, сертификат выпущен неверно.");
                        return;
                    }

                    if ($scope.user.typeOrg === 'fl') {
                        $scope.user.snils = regJson.snils
                    }
                    $scope.middleNameCert = middleName;
                    $scope.user.lastNameHolder = $scope.user.lastName = lastName;
                    $scope.user.firstNameHolder = $scope.user.firstName = firstName;
                    $scope.user.middleNameHolder = $scope.user.middleName = middleName;
                    $scope.user.inn = innVal;
                    $scope.user.ogrn = ogrn;
                    $scope.user.email = email;
                    $scope.certspin = 'stop';

                    if (!$scope.user.lastName || !$scope.user.firstName ||
                        ($scope.user.typeOrg !== 'nr' && !$scope.user.inn)) {
                        dialogSrv.showDialogMsg($scope, "Выбранный сертификат не валиден. Отсутствуют обязательные поля!");
                        $scope.user.fullName = '';
                        $scope.user.lastNameHolder = '';
                        $scope.user.firstNameHolder = '';
                        $scope.user.middleNameHolder = '';
                        $scope.user.lastName = '';
                        $scope.user.firstName = '';
                        $scope.user.middleName = '';
                        $scope.user.inn = '';
                        $scope.user.ogrn = '';
                        $scope.user.email = '';
                    }

                    if (regJson.typeOrg!=='ul' && !$scope.selectedFlInn && !/^[0]+$/g.test(innVal)) {
                        httpSrv.http({
                            url: '/auth/registration/validateInn',
                            data: {param: innVal, message: $scope.user.typeOrg},
                            block: true
                        }).then(function successCallback(response) {
                            if (!response.data.success) {
                                $scope.alertObjRegistration = alertSrv.getErrorMsg(response.data.message);
                            } else {
                                $scope.active.show = true;
                            }
                        })
                    } else {
                        if(!$scope.selectedFlInn || $scope.regType==='fl' || regJson.typeOrg==='ul')
                            $scope.active.show = true;
                    }
                }

                function bindFormEnterPress() {
                    var form = document.querySelector("form[name='form.registerForm']");
                    if (!form) return;
                    form.removeEventListener("keypress", formClickHandler, true);
                    form.addEventListener("keypress", formClickHandler, true);

                    function formClickHandler(e) {
                        if (e.keyCode == 13) {
                            if (e.defaultPrevented) return;
                            if (e.target.tagName == "INPUT") e.preventDefault();
                            registerFn();
                            return false;
                        }
                    }
                }
                function uploadCertFn(cert) {
                    var a = document.createElement("a");
                    var fName = cert.getInfo;
                    fName = fName ? (fName +".cer") : "сертификат.cer";
                    var blob = new Blob([cert.base64Value], {type: "application/octet-stream;base64"});
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, fName);
                        setTimeout(function () {
                            window.URL.revokeObjectURL(blob)
                        }, 500);
                    } else {
                        a.href  = 'data:application/octet-stream;base64,' + cert.base64Value;
                        a.download = fName;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                        }, 500);
                    }


                }

                /**
                 * Обновление справочника
                 * @param object - тип справочника
                 * @param value - введенные значения
                 * @param refreshScrolling - обновление данных при прокрутке
                 */
                function refreshDictFn(object, value, refreshScrolling) {
                    dictionarySrv.refreshDictionary($scope, object, value, refreshScrolling)
                }

                function init() {
                    $scope.form = {registerForm: {}};
                    $scope.status = {};
                    $scope.isChecked = true;
                    $scope.active = {};
                    $scope.cert = {};
                    $scope.profile = {};
                    $scope.user = {regAddress: {oksmCode: OKSM_RUS, oksmName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'}, realAddress: {}};
                    $scope.prevRealAddress = {oksmCode: OKSM_RUS, oksmName: 'РОССИЙСКАЯ ФЕДЕРАЦИЯ'};
                    $scope.cadesPlugin = {};
                    $scope.certspin = {};
                    $scope.captcha = {};
                    $scope.menu = 'registration';
                    $scope.submitted = false;
                    $scope.processing = false;
                    $scope.dialog = {};
                    $scope.dictionary = dictionarySrv.getRemoteData();
                    $scope.infiniteReqScroll = dictionarySrv.infiniteReqScroll($scope);
                    $scope.files;
                    $scope.showFileSelectBtn = true;
                    resetFn();

                    document.getElementById("container").addEventListener("keydown", function (e) {
                        var keyCode = e.which || e.keyCode;
                        if (keyCode == 9 && e.target.tagName == "INPUT") {
                            window.scrollTo(0, e.target.getBoundingClientRect().top + window.pageYOffset - 200);
                        }
                    })

                    propertiesService.getProperty("mchd.url").then((host) => {
                        $scope.mchdLink = host;
                        timeout($scope);
                    })
                }
                $scope.getAgentInn = () => {
                    return {"agentInn": $scope.selectedFlInn, "token": localStorage.getItem("key")}
                }
                $scope.showOrgPerson = () =>
                    ['ul','nr'].includes($scope.user.typeOrg) || ($scope.user.typeOrg === 'ip' && $scope.selectedFlInn && $scope.regType==='mchd')
                $scope.checkRegisteredUl = () => {
                    if('ul' === $scope.user.typeOrg && $scope.user?.kpp?.length === 9) {
                        httpSrv.http({
                            url: '/auth/registration/validateInnKpp',
                            data: {param: $scope.user?.inn, id: $scope.user?.kpp, message: $scope.user.typeOrg},
                            block: true
                        }).then(function successCallback(response) {
                            if (!response.data.success) {
                                $scope.form.registerForm['kpp'].$setValidity('kpp', false);
                                $scope.ulKppAlert = response.data.message;
                            } else {
                                $scope.form.registerForm['kpp'].$error = {};
                                $scope.form.registerForm['kpp'].$setValidity('kpp', true);
                                $scope.active.show = true;
                            }
                        })
                    } else
                        delete $scope.ulKppAlert;
                }
            }]
    });

