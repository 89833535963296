/**
 * @author sergeyu
 *
 */
angular.module('uetp').service('explanationOrgSrv', ['$state', 'httpSrv', 'dictionarySrv',
    function ($state, httpSrv, dictionarySrv) {
        /**
         * Создание документа
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.createExplanation = function (procType, procId, typeExplanation) {
            var url;
            url =  '/' + procType + '/' + procId + '/' + typeExplanation + '/create'
            return httpSrv.http({
                url:url
            })
        };
        /**
         * Получение данных для подписи
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param data - данные для получения хеша
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.getDataForSign = function (procType, procId, data, typeExplanation) {
            var url = '/' + procType + '/' + procId + '/' + typeExplanation + '/getDataForSign';
            return httpSrv.http({
                url: url,
                data: data
            })
        };
        /**
         * Отправка документа
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param data - данные для отправка с подписью
         * @param typeExplanation - тип разъяснения
         * @returns {*}
         */
        this.sendSignData = function (procType, procId, data, typeExplanation) {
            var url = '/' + procType + '/' + procId + '/' + typeExplanation + '/send';
           
            return httpSrv.http({
                url:url,
                data: data
            })
        };
        /**
         * Получение Запроса на разъяснение из mq
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param reqId - идентификатор "Запроса на разъяснение"
         * @returns {*}
         */
        this.getReqExplanation = function (procType, procId, reqId) {
            return httpSrv.http({
                method: 'GET',
                url: `/message/api/v2/readMessage/${reqId}`
            })
        };

        /**
         * Получение Запроса на разъяснение (таблица explanation_request_org)
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param reqId - идентификатор "Запроса на разъяснение"
         * @returns {*}
         */
        this.getExplanationReqOrg = function (procType, procId, reqId) {
            return httpSrv.http({
                method: 'GET',
                url: `/organizer/explreqs/byRequestId/${reqId}`
            })
        };

        /**
         * Получение Разъяснение
         * @param procType - тип процедуры
         * @param procId - идентификатор процедуры
         * @param expId - идентификатор "Разъяснения"
         * @returns {*}
         */
        this.getExplanation = function (procType, procId, expId) {
            var url = '/message/api/v2/readMessage/' + expId;
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
       
        this.getProcedureInfo = function (procType, procId) {
            var url = '/' + procType + '/simpleProcedureInfo/' + procId ;
            return httpSrv.http({
                method: 'GET',
                url: url
            })
        };
        /**
         * Получение форматированного имени участника
         * @param data - данные по учатнику
         * @returns {string}
         */
        this.getParticipantName = function (data) {
            var requisites = '',
                base = data.name + ',<br>',
                addressLegal = data.legalAddress ? '<br>Юридический адрес: ' + data.legalAddress : '',
                addressReal = data.postalAddress ? '<br>Фактический адрес: ' + data.postalAddress : '',
                msp = '';
            var getField = function (name, data) {
                if (data) {
                    return ', ' + name + ':' + data
                }
                return ''
            };


            if (data.ul || data.orgUL) {
                requisites = data.ul || data.orgUL;
                base += 'ИНН:' + requisites.inn + getField('КПП', requisites.kpp) + getField('ОГРН', requisites.ogrn) + ',';
            }
            else if (data.fl || data.orgFL) {
                requisites = data.fl || data.orgFL;
                base += 'ИНН:' + requisites.inn + ',';
            }
            else if (data.ip || data.orgIP) {
                requisites = data.ip || data.orgIP;
                base += 'ИНН:' + requisites.inn + ',';
            }
            else if (data.nr || data.orgNonResident) {
                requisites = data.nr || data.orgNonResident;
                base += 'Идентификационный номер:' + requisites.code + getField('Страна', requisites.countryName) + ',';
            }
            if (requisites.msp) {
                var nameMSP;
                dictionarySrv.getStaticDictByType('mspCategory').forEach(function (el) {
                    if (el.id === requisites.msp) {
                        nameMSP = el.name
                    }
                });
                msp = 'Субъект малого и среднего предпринимательства, ' + nameMSP;
                msp = '<br>' + msp
            }
            return base + msp + ' ' + addressLegal + (addressReal ? ', ' + addressReal : '')
                + getField('<br>Телефон', data.phone)
                + getField('<br>Адрес электронной почты', data.email);
        };

    }]);