/**
 * @author: sergeyu
 * Профиль пользователя
 */
angular.module('uetp')
    .component('profile', {
        templateUrl: 'app/components/profile/profile.html',
        bindings: {
            hasFiliations: '<',
        },
        controller: ['$scope', '$rootScope', '$state', '$stateParams', 'desktopSrv','authSrv', 'profileSrv',
            function ($scope, $rootScope, $state, $stateParams, desktopSrv, authSrv, profileSrv) {
                $rootScope.$on('cabTypeChange', _initShowPanel);

                this.$onInit = function () {
                    $scope.cabType = desktopSrv.getCabType;
                    $scope.model = {
                        showPanel: 'requisites',
                        showRequisite: true, // реквизиты
                        showCustomers: false, // Заказчики
                        showActions: false, // Действия
                        showUserInfo: true, // пользователь
                        showAgents: true, // прдеставители организации
                        showTariffsProfile: false, // тафифы
                        showPersonalAccount: true // лицевой счет
                    };
                    $scope.hasFiliationPanel = $scope.cabType() === 'participant' && this.hasFiliations?.data?.success && this.hasFiliations?.data?.items?.length>0;

                    _initShowPanel();
                    _refreshView();
                    profileSrv.checkAccredSecured().then(function (checkRes) {
                        authSrv.updateOrgAccreditation(checkRes.data.result);
                    });
                };
                $scope.setActivePanel = setActivePanelFn;

                /**
                 * Инициализация панели профиля по типу ЛК
                 * @private
                 */
                function _initShowPanel() {
                    const cabType = $scope.cabType(), userRole = authSrv.getAuth().userRole;
                    $scope.user = authSrv.getUser();

                    $scope.model.showPwdChange = true;

                    if (cabType === 'admincab') {
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showVersionInfo = true;
                    }
                    if (cabType === 'support') {
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showAgents = false;
                        $scope.model.showVersionInfo = true;
                    }
                    if (cabType === 'manager') {
                        $scope.model.showPanel = 'userInfo';
                        $scope.model.showRequisite = false;
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showAgents = false;
                    }
                    if (cabType === 'inspector') {
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showAgents = false;
                    }
                    if (cabType === 'organizer') {
                        if (!userRole.includes('organizer_admin')) {
                            $scope.model.showAgents = false;
                        }
                        $scope.model.showCustomers = true;
                        $scope.model.showActions = true;
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showVersionInfo = true;
                    }
                    if (cabType === 'agent') {
                        $scope.model.showAgents = userRole.includes('bg_agent_admin');
                        $scope.model.showPersonalAccount = false;
                        $scope.model.showTariffsProfile = false;
                    }
                    if (cabType === 'participant') {

                        $scope.model.showFiliation = userRole.includes('participant_admin', 'operator_support_ro') && ['ul'].includes($scope.user.typeOrg) && 'L'!==$scope.user.nrType;
                        $scope.model.showAccreditation = userRole.includes('participant_admin');
                        $scope.model.showTariffsProfile = true;
                        $scope.model.showPwdChange = $scope.user.changePwdAccess;
                        if (userRole.includes('user')) {
                            $scope.model.showAgents = false;
                        }
                        $scope.model.showVersionInfo = true;
                    }
                    if (cabType === 'accountant') {
                        $scope.model.showAgents = false;
                    }
                    if (cabType === 'bank') {
                        $scope.model.showAgents = userRole.includes('bank_admin');
                        $scope.model.showRequisite = false;
                        // вкладка "Реквизиты" скрыта - отображаем вкладку "Пользователи"
                        $scope.model.showPanel = "userInfo";
                    }

                    if ($stateParams.activeProfileTab){
                        $scope.model.showPanel = $stateParams.activeProfileTab;
                    }
                }

                /**
                 * Обновление состояния при перегрузке страницы по F5 или другим способом
                 * @private
                 */
                function _refreshView() {
                    var activePanel = $stateParams.setActivePanel;
                    if (activePanel && $scope.model.showPanel !== activePanel) {
                        setActivePanelFn(activePanel);
                    }
                }

                /**
                 * Выставление активной вкладки
                 * @param type - тип вкладки
                 */
                function setActivePanelFn(type) {
                    //$scope.model.showPanel = type;
                    $state.go('profile', {activeProfileTab: type});
                }

            }]
    });

